<template>
    <div class="main">
        <el-container>
            <el-header>
                <el-form
                    :model="searchForm"
                    inline
                    label-position="left"
                    size="small"
                    @submit.native.prevent
                >
                    <el-form-item label="名称">
                        <el-input
                            v-model="searchForm.name"
                            clearable
                            size="small"
                            placeholder="会议室名称"
                        />
                    </el-form-item>
                    <el-form-item label="会议时间">
                        <el-date-picker
                            v-model="searchForm.date"
                            type="daterange"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            :default-time="['00:00:00', '23:59:59']"
                            :clearable="false"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        />
                    </el-form-item>
                    <el-form-item>
                        <el-button
                            type="primary"
                            icon="el-icon-search"
                            size="small"
                            @click="onSubmit"
                        >查询</el-button>
                    </el-form-item>
                </el-form>
            </el-header>

            <el-main>
                <el-table
                    :header-cell-style="{ background: '#FAFAFA' }"
                    :data="tableData"
                    border
                    stripe
                >
                    <el-table-column prop="meetDate" label="会议时间" align="center" width="300" />

                    <el-table-column prop="name" label="会议室名称" align="center" />

                    <el-table-column prop="title" label="会议标题" align="center" />

                    <el-table-column prop="operator_user_name" label="预定人" align="center" />

                    <el-table-column label="会议状态" align="center">
                        <template slot-scope="{row}">
                            <span
                                :style="{ color: row.status === 0 ? 'orange' : row.status === 1 ? 'green' : row.status === 2 ? '' : 'gray' }"
                            >{{ row.state }}</span>
                        </template>
                    </el-table-column>
    <!-- :href="'https://whyz.jingxuebao.com:3000/#/meeting-view?id=' + row.id" -->
                    <el-table-column label="操作" align="center">
                        <template slot-scope="{row}">
                            <el-link
                                type="primary"
                                :href="'/whyz/meeting-view?id=' + row.id"
                                target="_top"
                                style="margin-right:10px;"
                            >投屏到大屏幕</el-link>
                            <el-button type="text" @click="handleEdit(row)">查看数据统计</el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <Pagination
                    :pageIndex="pageIndex"
                    :pageSize="pageSize"
                    :total="total"
                    @handleSizeChange="handleSizeChange"
                    @handleCurrentChange="handleCurrentChange"
                />
            </el-main>
        </el-container>
        <StatisticsDetail :visible="visible" :row="row" @handleClose="handleClose" />
    </div>
</template>

<script>
import Pagination from '@/components/Pagination.vue'
import StatisticsDetail from '@/components/meeting/StatisticsDetail.vue'
import api from '../api'
import dayjs from 'dayjs'

export default {
    components: { Pagination, StatisticsDetail },
    data() {
        return {
            visible: false,
            row: {},

            searchForm: {
                name: "",
                date: [],
            },
            deviceList: [], // 可选择的设备列表
            tableData: [], // 列表数据
            total: 0, // 总条数
            pageIndex: 1, // 起始页码
            pageSize: 10, // 每页条数
        }
    },
    methods: {
        // 分页器
        handleCurrentChange(val) {
            this.pageIndex = val
            this.getList()
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.getList()
        },

        // 查询
        onSubmit() {
            this.pageIndex = 1
            this.getList()
        },

        // 查看会议大屏
        handleLookMeetView(row) {
            this.$router.push({
                path: '/meeting-view',
                query: { id: row.id }
            })

        },

        // 查看数据统计
        handleEdit(row) {
            this.row = row
            this.visible = true
        },

        handleClose(bool) {
            this.visible = false
            this.row = {}
            if (bool) this.getList()
        },

        // 数字转换
        changNum(n) {
            const arr = ['日', '一', '二', '三', '四', '五', '六']
            return arr[n]
        },

        // 会议状态转换
        changStatus(n) {
            const arr = ['未开始', '进行中', '已结束', '已取消']
            return arr[n]
        },

        // 请求列表
        async getList() {
            const date = this.searchForm.date
            const startDate = dayjs(date[0]).valueOf()
            const endDate = dayjs(date[1]).valueOf()
            const res = await api.pass.meetRecordQuery({
                name: this.searchForm.name,
                start_date: startDate,
                end_date: endDate,
                page_index: this.pageIndex,
                page_size: this.pageSize,
                sort_name: 'name',
                sort_order: 'asc'
            });
            if (res.code !== 0) this.$message.error(res.message);
            const list = res.data.list || []
            this.tableData = list.map(item => {
                item.state = this.changStatus(item.status)
                const startTime = dayjs(item.start_date)
                const endTime = dayjs(item.end_date)
                const date = startTime.format('YYYY/MM/DD')
                const week = startTime.get('day')
                const startDate = startTime.format('HH:mm')
                const endDate = endTime.format('HH:mm')

                item.meetDate = `${date}（周${this.changNum(week)}） ${startDate} - ${endDate}`
                return item
            })
            this.total = res.data.total
        },
    },

    async mounted() {
        const currentDate = dayjs().format('YYYY-MM-DD')
        const nextDate = dayjs().add(1, 'day').format('YYYY-MM-DD')
        this.searchForm.date = [`${currentDate} 00:00:00`, `${nextDate} 23:59:59`]
        await this.getList()
    }
}
</script>

<style lang="scss" scoped>
</style>
