var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('el-container',[_c('el-header',[_c('el-form',{attrs:{"model":_vm.searchForm,"inline":"","label-position":"left","size":"small"},nativeOn:{"submit":function($event){$event.preventDefault();}}},[_c('el-form-item',{attrs:{"label":"来访时间"}},[_c('el-date-picker',{attrs:{"type":"datetimerange","range-separator":"至","start-placeholder":"开始日期","end-placeholder":"结束日期","value-format":"yyyy-MM-dd HH:mm:ss","default-time":['00:00:00', '23:59:59'],"clearable":false},model:{value:(_vm.searchForm.dateTimes),callback:function ($$v) {_vm.$set(_vm.searchForm, "dateTimes", $$v)},expression:"searchForm.dateTimes"}})],1),_c('el-form-item',{attrs:{"label":"访客姓名"}},[_c('el-input',{attrs:{"size":"small","placeholder":"请输入访客姓名","clearable":""},model:{value:(_vm.searchForm.userName),callback:function ($$v) {_vm.$set(_vm.searchForm, "userName", $$v)},expression:"searchForm.userName"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary","icon":"el-icon-search","size":"small"},on:{"click":_vm.onSubmit}},[_vm._v("查询")])],1)],1)],1),_c('el-main',[_c('el-table',{attrs:{"header-cell-style":{ background: '#FAFAFA' },"data":_vm.tableData,"border":"","stripe":""}},[_c('el-table-column',{attrs:{"prop":"user_name","label":"访客姓名","align":"center","width":"100"}}),_c('el-table-column',{attrs:{"prop":"gender","label":"性别","align":"center","width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.gender)?_c('span',[_vm._v(_vm._s(row.gender === 1 ? '男' : '女'))]):_c('span',[_vm._v("未知")])]}}])}),_c('el-table-column',{attrs:{"prop":"temperature","label":"体温(℃)","align":"center","width":"75"}}),_c('el-table-column',{attrs:{"prop":"id_number_str","label":"身份证号","align":"center","width":"185"}}),_c('el-table-column',{attrs:{"prop":"phone_number","label":"手机号","align":"center","width":"120"}}),_c('el-table-column',{attrs:{"prop":"identify_time_str","label":"来访时间","align":"center","width":"100"}}),_c('el-table-column',{attrs:{"prop":"leave_time_str","label":"离开时间","align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.leave)?_c('span',[_vm._v(_vm._s(row.leave_time_str))]):_c('span',[_vm._v("--")])]}}])}),_c('el-table-column',{attrs:{"prop":"device_name","label":"登记设备","align":"center"}}),_c('el-table-column',{attrs:{"label":"登记拍照","align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.path)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.showPhoto(row)}}},[_vm._v("查看")]):_c('span',[_vm._v("暂无")])]}}])}),_c('el-table-column',{attrs:{"prop":"reason","label":"来访原因","align":"center","min-width":"100","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"interviewed_user_name","label":"被访人","align":"center"}}),_c('el-table-column',{attrs:{"prop":"confirm","label":"被访人电话确认","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.confirm === 1 ? '已确认' : '未确认'))]}}])}),_c('el-table-column',{attrs:{"label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleEdit(row)}}},[_vm._v("登记信息")]),_c('el-button',{attrs:{"type":"text","disabled":row.leave === 1},on:{"click":function($event){return _vm.handleLeave(row)}}},[_vm._v("离校确认")])]}}])})],1),_c('Pagination',{attrs:{"pageIndex":_vm.pageIndex,"pageSize":_vm.pageSize,"total":_vm.total},on:{"handleSizeChange":_vm.handleSizeChange,"handleCurrentChange":_vm.handleCurrentChange}})],1)],1),_c('VisitorRecordEdit',{attrs:{"visible":_vm.visible,"row":_vm.row},on:{"handleClose":_vm.handleClose}}),_c('el-dialog',{attrs:{"width":"800px","visible":_vm.photoVisible,"top":"2vh"},on:{"update:visible":function($event){_vm.photoVisible=$event}}},[_c('div',{staticStyle:{"text-align":"center","padding":"0 0 30px"}},[_c('img',{staticStyle:{"max-width":"100%"},attrs:{"src":_vm.photoSrc}})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }