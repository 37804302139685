<template>
<div id="cards">
    <div class="cards-title">实时签到</div>
    <div class="cards-box">
        <div class="card-item" v-for="(item, i) in cards" :key="i">
            <div class="card-header">
                <div class="card-header-left">{{item.timeStr}}</div>
                <div class="card-header-right">{{item.user_name}}</div>
            </div>
            <div class="pic-box">
                <el-image :src="item.path" class="user-pic">
                    <div slot="error" class="user-pic-error">
                        暂无图片
                    </div>
                </el-image>
            </div>

        </div>
        <div class="card-item" v-for="item in 12-cards.length" :key="item+'666'">
            <div class="card-header">
                <div class="card-header-left" />
                <div class="card-header-right" />
            </div>
            <div class="pic-box">
                <el-image class="user-pic">
                    <div slot="error" class="user-pic-error">
                        暂无图片
                    </div>
                </el-image>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'Cards',
    props: ['tableData'],

    data() {
        return {
            cards: [],
        }
    },

    watch: {
        tableData(val, oldVal) {
            // console.log('Card val:', val)
            // console.log('Card oldVal:', oldVal)
            const list = val.filter(item => item.status !== 0)
            list.sort((a, b) => {
                return b.timeStr.localeCompare(a.timeStr) || b.user_name.localeCompare(a.user_name);
            });
            this.cards = list.slice(0, 12)
        }
    }

}
</script>

<style lang="scss">
#cards {
    height: 50%;
}

.cards-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 0.4%;
}

.cards-box {
    // display: flex;
    // justify-content: space-between;
    height: 84%;

    .card-item {
        float: left;
        background-color: rgba(6, 30, 93, 0.5);
        border-top: 2px solid rgba(1, 153, 209, 0.5);
        width: 16%;
        height: 49%;
        margin-bottom: 1%;
        margin-right: 0.8%;
        display: flex;
        flex-direction: column;

        &:nth-child(6n){
            margin-right: 0;
        }
    }



    .card-header {
        display: flex;
        height: 15%;
        align-items: center;
        justify-content: space-between;

        .card-header-left {
            font-size: 18px;
            font-weight: bold;
            padding-left: 18px;
        }

        .card-header-right {
            padding-right: 18px;
            font-size: 24px;
            color: #03d3ec;
        }
    }

    .pic-box {
        width: 100%;
        height: 85%;
        display: flex;
        justify-content: center;
        align-items: center;
        // background: lightblue;

        .user-pic {
            width: 90%;
            height: 90%;
        }

        .user-pic-error {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #e6e8eb;
            color: #969797;
        }
    }

    .ring-charts {
        width: 100%;
        height: 55%;
    }

    .card-footer {
        height: 25%;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .card-footer-item {
        padding: 5px 10px 0px 10px;
        box-sizing: border-box;
        width: 40%;
        background-color: rgba(6, 30, 93, 0.7);
        border-radius: 3px;

        .footer-title {
            font-size: 15px;
            margin-bottom: 5px;
        }

        .footer-detail {
            font-size: 20px;
            color: #1294fb;
            display: flex;
            font-size: 18px;
            align-items: center;

            .dv-digital-flop {
                margin-right: 5px;
            }
        }
    }
}
</style>
