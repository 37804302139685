<template>
<div id="data-view">
    <dv-full-screen-container>
        <TopHeader :signTime="signTime" />

        <div class="main-content">
            <!-- <DigitalFlop /> -->

            <div class="block-left-right-content">
                <!-- <RankingBoard /> -->

                <div class="block-top-bottom-content">
                    <div class="block-top-content">
                        <WaterLevelChart :chuqinlv="statisticsData.chuqinlv" />

                        <RoseChart :statisticsData="statisticsData" />

                    </div>
                    <Cards :tableData="tableData" />

                </div>
                <ScrollBoard :tableData="tableData" />
            </div>
        </div>
    </dv-full-screen-container>

    <!-- <div class="on-back">返回</div> -->
    <el-button class="on-back" circle @click="handleBack">返回</el-button>
</div>
</template>

<script>
// import screenfull from "screenfull"
import TopHeader from './TopHeader'
// import DigitalFlop from './DigitalFlop'
// import RankingBoard from './RankingBoard'
import RoseChart from './RoseChart'
import WaterLevelChart from './WaterLevelChart'
import ScrollBoard from './ScrollBoard'
import Cards from './Cards'

import api from '@/api'
import { timestampToDateStr } from '@/utils'

export default {
    name: 'DataView',
    components: {
        TopHeader,
        // DigitalFlop,
        // RankingBoard,
        RoseChart,
        WaterLevelChart,
        ScrollBoard,
        Cards,
    },

    data() {
        return {
            timer: null,

            signTime: "",

            tableData: [],

            statisticsData: {
                totalCount: 0,
                zhunshi: 0,
                chidao: 0,
                weichuxi: 0,
                chuqinlv: 0
            }
        }
    },

    methods: {
        // screen() {
        //     // 如果不允许进入全屏，发出不允许提示
        //     if (!screenfull.enabled) return this.$message.warning("您的浏览器不能全屏");

        //     screenfull.toggle();
        //     // this.$message.success("全屏啦");
        // },

        // screen() {
        //     const element = document.documentElement;
        //     if (this.fullscreen) {
        //         if (document.exitFullscreen) {
        //             document.exitFullscreen();
        //         } else if (document.webkitCancelFullScreen) {
        //             document.webkitCancelFullScreen();
        //         } else if (document.mozCancelFullScreen) {
        //             document.mozCancelFullScreen();
        //         } else if (document.msExitFullscreen) {
        //             document.msExitFullscreen();
        //         }
        //     } else {
        //         if (element.requestFullscreen) {
        //             element.requestFullscreen();
        //         } else if (element.webkitRequestFullScreen) {
        //             element.webkitRequestFullScreen();
        //         } else if (element.mozRequestFullScreen) {
        //             element.mozRequestFullScreen();
        //         } else if (element.msRequestFullscreen) {
        //             // IE11
        //             element.msRequestFullscreen();
        //         }
        //     }
        //     this.fullscreen = !this.fullscreen;
        // },

        handleBack() {
            // screenfull.toggle();
            this.$router.go(-1)
            // this.$router.push({
            //     name: 'MeetingTabs',
            //     params: {
            //         name: '3'
            //     }
            // });
        },

        // 会议签到状态转换
        changStatus(n) {
            const arr = ['未签到', '准时', '迟到']
            return arr[n]
        },

        // 获取统计详情
        async getDetail(id) {
            const res = await api.pass.meetStatisticsQuery({ meet_id: id });
            if (res.code !== 0) this.$message.error(res.message);
            const data = res.data
            const signInStartTime = timestampToDateStr(data.sign_in_start_date, 'HH:mm')
            const signInEndTime = timestampToDateStr(data.sign_in_end_date, 'HH:mm')
            this.signTime = `${signInStartTime} ~ ${signInEndTime}`
            const totalCount = data.meet_user.length
            const zhunshi = data.on_time_count
            const chidao = data.user_late_count
            const weichuxi = totalCount - zhunshi - chidao
            const chuqinlv = Math.ceil((zhunshi + chidao) / totalCount * 100) || 0

            this.statisticsData.totalCount = totalCount
            this.statisticsData.zhunshi = zhunshi
            this.statisticsData.chidao = chidao
            this.statisticsData.weichuxi = weichuxi
            this.statisticsData.chuqinlv = chuqinlv

            const list = data.meet_user || []
            this.tableData = list.map(item => {
                item.state = this.changStatus(item.status)
                item.onTime = timestampToDateStr(item.identify_time, 'YYYY-MM-DD HH:mm:ss')
                item.timeStr = timestampToDateStr(item.identify_time, 'HH:mm:ss')
                return item
            })
        },
    },

    mounted() {
        // this.screen()
        const id = this.$route.query.id
        this.getDetail(id)
        this.timer = setInterval(() => {
            this.getDetail(id)
        }, 5000)
    },

    destroyed() {
        this.timer = null
    }
}
</script>

<style lang="scss">
.on-back {
    position: absolute;
    right: 2%;
    top: 3%;
    z-index: 99999;
    width: 80px;
    height: 80px;
    // line-height: 80px;
    text-align: center;
    border-radius: 50%;
    font-size: 25px;
    background: grey;
    color: white;
    opacity: 0.1;
}

.on-back:hover {
    opacity: 0.6;
}

#data-view {
    width: 100%;
    height: 100%;
    background-color: #030409;
    color: #fff;

    #dv-full-screen-container {
        background-image: url('./img/bg.png');
        background-size: 100% 100%;
        box-shadow: 0 0 3px blue;
        display: flex;
        flex-direction: column;
    }

    .main-content {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .block-left-right-content {
        flex: 1;
        display: flex;
        margin-top: 10px;
    }

    .block-top-bottom-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding-left: 20px;
    }

    .block-top-content {
        height: 50%;
        display: flex;
        flex-grow: 0;
        box-sizing: border-box;
        padding-bottom: 20px;
    }
}
</style>
