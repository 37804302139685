<template>
<div class="main">
    <el-container>
        <el-header>
            <el-form :model="searchForm" inline label-position="left" size="small" @submit.native.prevent>
                <el-form-item label="名称">
                    <el-input v-model="searchForm.name" clearable size="small" placeholder="会议室名称" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" v-waves icon="el-icon-search" size="small" @click="onSubmit">查询</el-button>
                </el-form-item>
            </el-form>
        </el-header>

        <el-main>
            <el-table :header-cell-style="{background:'#FAFAFA'}" :data="tableData" border stripe>
                <el-table-column prop="name" label="会议室名称" align="center" />

                <el-table-column label="会议预定" align="center" min-width="300">
                    <template slot-scope="{row}">
                        <div class="content">
                            <div v-for="(item,i) in row.meet_info" :key="i" class="content-box">
                                <div class="date">{{item.date}} {{item.time}}</div>
                                <div class="user">{{item.user}}</div>
                                <div class="state">
                                    <span @click.ctrl="clickCtrlLeft(item)">{{item.state}}</span>
                                </div>
                                <div class="btn-box">
                                    <el-button v-if="item.status<=0" class="edit-btn" type="primary" size="mini" @click="handleEdit({id:item.meet_id,name:row.name,meet_room_id:row.meet_room_id})">修 改</el-button>
                                    <el-button v-if="item.status<=0 && item.show" type="warning" size="mini" icon="el-icon-star-off" circle @click="handlePushUser(item)" />
                                </div>
                            </div>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column label="操作" align="center">
                    <template slot-scope="{row}">
                        <el-button type="text" @click="handleAdd(row)">新增会议</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <Pagination :pageIndex="pageIndex" :pageSize="pageSize" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" />
        </el-main>
    </el-container>
    <MeetingEdit :visible="visible" :row="row" @handleClose="handleClose" />
</div>
</template>

<script>
import Pagination from '@/components/Pagination.vue'
import MeetingEdit from '@/components/meeting/MeetingEdit.vue'
import api from '../api'
import dayjs from 'dayjs'
export default {
    components: { Pagination, MeetingEdit },
    data() {
        return {
            visible: false,
            row: {},

            searchForm: {
                name: '',
            },
            tableData: [], // 列表数据
            total: 0, // 总条数
            pageIndex: 1, // 起始页码
            pageSize: 10, // 每页条数

        }
    },
    methods: {
        clickCtrlLeft(row) {
            row.show = !row.show
        },

        // 分页器
        handleCurrentChange(val) {
            this.pageIndex = val
            this.getList()
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.getList()
        },

        // 查询
        onSubmit() {
            this.pageIndex = 1
            this.getList()
        },

        // 新增
        handleAdd(row) {
            this.row = row
            this.visible = true
        },

        // 编辑
        handleEdit(row) {
            this.row = row
            this.visible = true
        },

        handleClose(bool) {
            this.visible = false
            this.row = {}
            if (bool) this.getList()
        },

        // 推送人员到设备
        async handlePushUser(row) {
            const res = await api.pass.meetUserPushDevice({ id: row.meet_id })
            row.show = false
            this.$message.info(res.message)

        },

        // 数字转换
        changNum(n) {
            const arr = ['日', '一', '二', '三', '四', '五', '六']
            return arr[n]
        },

        // 会议状态转换
        changStatus(n) {
            const arr = ['未开始', '进行中', '已结束', '已取消']
            return arr[n]
        },

        // 请求列表
        async getList() {
            const res = await api.pass.meetQuery({
                name: this.searchForm.name,
                page_index: this.pageIndex,
                page_size: this.pageSize,
                sort_name: 'name',
                sort_order: 'asc'
            });
            if (res.code !== 0) this.$message.error(res.message);
            const list = res.data.list || []
            this.tableData = list.map(item => {
                item.meet_info.map(p => {
                    const startTime = dayjs(p.start_date)
                    const endTime = dayjs(p.end_date)
                    const date = startTime.format('YYYY/MM/DD')
                    const week = startTime.get('day')
                    const startDate = startTime.format('HH:mm')
                    const endDate = endTime.format('HH:mm')

                    p.date = `${date}（周${this.changNum(week)}）`
                    p.time = `${startDate} - ${endDate}`
                    p.user = `预定人：${p.operator_user_name}`
                    p.state = `（${this.changStatus(p.status)}）`
                    p.show = false
                    return p
                })

                return item
            })
            this.total = res.data.total
        },
    },

    async mounted() {
        await this.getList()
    }
}
</script>

<style lang="scss" scoped>
.content {
    min-width: 400px;
    overflow-x: auto;
}

.content-box {
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5px;

    .date {
        width: 250px;
        min-width: 250px;
        white-space: nowrap;
    }

    .user {
        width: 180px;
        min-width: 180px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .state {
        width: 80px;
        min-width: 80px;
        white-space: nowrap;
    }

    .btn-box {
        width: 200px;
        display: flex;
        align-items: center;
    }
}

.content-box:nth-child(1) {
    margin-top: 0px;
}

.edit-btn {
    height: 19px;
    line-height: 4px;
    margin-left: 25px;
}
</style>
