<template>
  <el-dialog
    width="1100px"
    :visible.sync="visible"
    top="2vh"
    :before-close="()=>handleClose(false)"
    :close-on-click-modal="false"
    @open="dialogOpen"
  >
    <div slot="title" class="dialog-title">{{dialogTitle}}</div>
    <el-table
      :header-cell-style="{background:'#FAFAFA'}"
      :data="tableData"
      height="555"
      border
      stripe
    >
      <el-table-column
        prop="device_name"
        label="设备名称"
        width="200"
        align="center"
        show-overflow-tooltip
      />

      <el-table-column
        prop="device_serial_no"
        label="设备编号"
        width="180"
        align="center"
      />

      <el-table-column
        prop="content"
        label="详情"
        show-overflow-tooltip
      />

      <el-table-column
        prop="log_type_name"
        label="日志类型"
        width="110"
        align="center"
      />
      <el-table-column
        prop="create_time"
        label="创建时间"
        width="100"
        align="center"
      />
    </el-table>

    <el-pagination
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :current-page.sync="pageIndex"
      :page-sizes="[10, 20, 30]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      class="pagination-box"
    />
  </el-dialog>
</template>

<script>
import api from '../../api'
import {timestampToDateStr} from '@/utils'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      dialogTitle: '设备日志',
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
    }
  },

  methods: {
    dialogOpen() {
      this.device_id = this.row.id
      this.getData()
    },
    handleClose(bool) {
      this.$emit('handleClose', bool)
    },
    handleSizeChange() {
      this.getData()
    },
    handleCurrentChange() {
      this.getData()
    },
    getData() {
      api.pass
        .queryDeviceLog({
          // device_name: this.row.device_name,
          // device_serial_no: this.row.device_serial_no,
          page_index: this.pageIndex,
          page_size: this.pageSize,
          // sort_name: 'string',
          // sort_order: 'asc',
        })
        .then((res) => {
          if (res.code !== 0) this.$message.error(res.message);
          const type = ['错误', '消息', 'debug']
          const data = res.data.list || []
          data.forEach((item) => {
            item.create_time = timestampToDateStr(item.created_at,'YYYY-MM-DD HH:mm:ss')
            item.log_type_name = type[item.log_type - 1]
          })
          this.tableData = data
          this.total = res.data.total
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.footer-btn {
  width: 100px;
}

.footer-btn-cancel {
  margin-right: 10px;
}


</style>
