<template>
<div id="top-header">
    <dv-decoration-8 class="header-left-decoration" />
    <dv-decoration-5 class="header-center-decoration" />
    <dv-decoration-8 class="header-right-decoration" :reverse="true" />
    <div class="center-title">
        <div>会议签到</div>
        <!-- <div class="center-title-date">{{dateStr}}</div> -->
    </div>

    <div class="center-title-date center-title-left">{{dateStr}}</div>

    <div class="center-title-date center-title-right">
        请在<span style="color:skyblue;font-weight:bold">【{{signTime}}】</span>时间段内完成签到
    </div>

</div>
</template>

<script>
import dayjs from 'dayjs'

export default {
    name: 'TopHeader',
    props: ['signTime'],

    data() {
        return {
            timer: null,
            dateStr: ""
        }
    },

    methods: {
        changNum(n) {
            const arr = ['日', '一', '二', '三', '四', '五', '六']

            return arr[n]
        }
    },

    mounted() {
        this.timer = setInterval(() => {
            const date = dayjs()
            const week = date.get('day')
            this.dateStr = date.format(`MM月DD日 星期${this.changNum(week)} HH:mm:ss`)
        }, 1000)
    },

    beforeDestroy() {
        if (this.timer) this.timer = null
    }
}
</script>

<style lang="scss">
#top-header {
    position: relative;
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;

    .header-center-decoration {
        width: 40%;
        height: 60px;
        margin-top: 55px;
    }

    .header-left-decoration,
    .header-right-decoration {
        width: 25%;
        height: 60px;
    }

    .center-title {
        position: absolute;
        font-size: 48px;
        font-weight: bolder;
        text-align: center;
        left: 50%;
        top: 16%;
        transform: translateX(-50%);
    }

    .center-title-date {
        font-size: 27px;
    }

    .center-title-left {
        position: absolute;
        left: 1%;
        bottom: 7%;
    }

    .center-title-right {
        position: absolute;
        right: 1%;
        bottom: 7%;
    }
}
</style>
