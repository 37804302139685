<template>
  <el-dialog
    width="800px"
    :visible.sync="visible"
    top="2vh"
    :before-close="() => handleClose(false)"
    :close-on-click-modal="false"
    @open="handleOpen"
  >
    <div slot="title" class="dialog-title">{{dialogTitle}}</div>
    <el-form :model="formData" ref="ruleForm" :rules="rules" label-width="100px">
      <el-form-item label="规则名称" prop="name">
        <el-input
          v-model="formData.name"
          ref="input"
          placeholder="请输入规则名称（15字内）"
          autocomplete="off"
        />
      </el-form-item>
      <el-form-item label="通行设备" prop="device_id">
        <el-input
          v-model="formData.device"
          ref="input"
          placeholder="请选择设备"
          autocomplete="off"
          readonly
          @focus="handlePassDevice"
        >
          <el-button slot="suffix" type="text" @click="handlePassDevice">选择</el-button>
        </el-input>
      </el-form-item>
      <el-form-item label="通行用户" prop="user_id">
        <el-input
          v-model="formData.user"
          ref="input"
          placeholder="请选择用户"
          autocomplete="off"
          readonly
          @focus="handlePassUser"
        >
          <el-button slot="suffix" type="text" @click="handlePassUser">选择</el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button class="footer-btn footer-btn-cancel" size="small" @click="handleClose(false)">取 消</el-button>
      <el-button
        class="footer-btn"
        :loading="submitLoading"
        type="primary"
        size="small"
        @click="submitForm('ruleForm')"
      >保 存</el-button>
    </div>
    <ChooseDevices
      :visible="deviceVisible"
      :values="deviceDialogData"
      @handleEmit="handleDeviceClose"
    />

    <ChooseUsers :visible="userVisible" :values="userDialogData" @handleClose="handleUserClose" />
  </el-dialog>
</template>

<script>
import api from '../../api'
import ChooseDevices from '../ChooseDevices'
import ChooseUsers from '../ChooseUsers'
export default {
  components: { ChooseDevices, ChooseUsers },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Object,
      default: () => { },
    },
  },

  data() {
    return {
      dialogTitle: '新建规则',
      submitLoading: false,

      formData: {
        name: '',
        user: '',
        device: '',
      },

      rules: {
        name: [
          { required: true, message: '规则名称不能为空' },
          { min: 2, max: 16, message: '长度在2到15个字符之间' },
        ],
      },

      deviceDialogData: [],
      deviceVisible: false,
      userDialogData: [],
      userVisible: false,
    }
  },

  methods: {
    handleOpen() {
      if (this.row.id) {
        this.dialogTitle = '修改规则'
        this.formData.name = this.row.name
        this.formData.user =
          this.row.user_count > 0 ? '已选择 ' + this.row.user_count + ' 人' : ''
        this.formData.device =
          this.row.device_count > 0
            ? '已选择 ' + this.row.device_count + ' 台'
            : ''

        this.getPassDevice()
        this.getPassUser()
      } else {
        this.deviceDialogData = []
        this.userDialogData = []
      }
    },
    reset() {
      this.dialogTitle = '新建规则'
      this.formData = {
        name: '',
        user: '',
        device: '',
      }
    },
    // 关闭弹出层
    handleClose(bool) {
      this.reset()
      this.$refs.ruleForm.resetFields()
      this.$emit('handleClose', bool)
    },

    // 提交新增
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.submitLoading = true
          if (this.row.id) {
            const data = await api.pass.updateRule({
              id: this.row.id,
              name: this.formData.name,
              device_id: this.deviceDialogData,
              user_id: this.userDialogData.map((item) => item.id),
            })
            this.submitLoading = false

            if (data.code !== 0) {
              this.$message.error(data.message)
            } else {
              this.$message.success('操作成功')
              this.$refs[formName].resetFields()
            }
            this.handleClose(true)
          } else {
            const data = await api.pass.createRule({
              name: this.formData.name,
              device_id: this.deviceDialogData,
              user_id: this.userDialogData.map((item) => item.id),
            })
            this.submitLoading = false

            if (data.code !== 0) {
              this.$message.error(data.message)
            } else {
              this.$message.success('操作成功')
              this.$refs[formName].resetFields()
            }
            this.handleClose(true)
          }
        } else {
          console.log('操作失败')
          return false
        }
      })
    },
    getPassDevice() {
      api.pass
        .queryRuleDevice({
          id: this.row.id,
        })
        .then((res) => {
          if (res.data) {
            this.deviceDialogData = res.data.map((item) => item.device_id)
          } else {
            this.deviceDialogData = []
          }
        })
        .catch(() => {
          this.deviceDialogData = []
        })
    },
    handlePassDevice(row) {
      // if (this.row.id) {
      //   api.pass
      //     .queryRuleDevice({
      //       id: this.row.id,
      //     })
      //     .then((res) => {
      //       if (res.data) {
      //         this.deviceDialogData = res.data.map((item) => item.device_id)
      //       } else {
      //         this.deviceDialogData = []
      //       }
      //       this.deviceVisible = true
      //     })
      // } else {
      //   this.deviceDialogData = []
      //   this.deviceVisible = true
      // }
      this.deviceVisible = true
    },
    handleDeviceClose(data) {
      this.deviceVisible = false


      if (data) {
        this.deviceDialogData = data.map(item => item.id)
        this.formData.device =
          data.length > 0 ? '已选择 ' + data.length + ' 台' : ''
      }
    },
    getPassUser() {
      api.pass
        .queryRuleUser({
          id: this.row.id,
        })
        .then((res) => {
          if (res.data) {
            this.userDialogData = res.data.map((item) => {
              return { id: item.user_id, name: item.user_name }
            })
          } else {
            this.userDialogData = []
          }
        })
        .catch(() => {
          this.userDialogData = []
        })
    },
    handlePassUser() {
      // if (this.row.id) {
      //   api.pass
      //     .queryRuleUser({
      //       id: this.row.id,
      //     })
      //     .then((res) => {
      //       if (res.data) {
      //         this.userDialogData = res.data.map((item) => {
      //           return { id: item.user_id, name: item.user_name }
      //         })
      //       } else {
      //         this.userDialogData = []
      //       }
      //       this.userVisible = true
      //     })
      // } else {
      //   this.userDialogData = []
      //   this.userVisible = true
      // }
      this.userVisible = true
    },
    handleUserClose(data) {
      this.userVisible = false
      if (data) {
        this.userDialogData = data

        this.formData.user =
          data.length > 0 ? '已选择 ' + data.length + ' 人' : ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog-title {
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

.footer-btn {
  width: 100px;
}

.footer-btn-cancel {
  margin-right: 10px;
}
</style>
