<template>
<div id="rose-chart">
    <div class="rose-chart-title">出勤率</div>
    <dv-charts :option="option" />
</div>
</template>

<script>
export default {
    name: 'RoseChart',
    props: ['statisticsData'],

    data() {
        return {
            option: {
                series: [{
                    type: 'pie',
                    radius: '50%',
                    roseSort: false,
                    data: [
                        { name: '未签到', value: 3 },
                        { name: '准时', value: 0 },
                        { name: '迟到', value: 0 },
                    ],
                    insideLabel: {
                        show: false
                    },
                    outsideLabel: {
                        formatter: '{name} {percent}%',
                        labelLineEndLength: 20,
                        style: {
                            fill: '#fff'
                        },
                        labelLineStyle: {
                            stroke: '#fff'
                        }
                    },
                    roseType: true
                }],
                color: ['#da2f00', 'green', 'orange']
            }
        }
    },

    watch: {
        statisticsData: {
            handler(val, oldVal) {
                this.option.series[0].data[0].value = val.weichuxi
                this.option.series[0].data[1].value = val.zhunshi
                this.option.series[0].data[2].value = val.chidao

                this.option = { ...this.option }
            },
            deep: true
        },
    }

}
</script>

<style lang="scss">
#rose-chart {
    width: 65%;
    height: 100%;
    background-color: rgba(6, 30, 93, 0.5);
    border-top: 2px solid rgba(1, 153, 209, .5);
    box-sizing: border-box;

    .rose-chart-title {
        height: 50px;
        // font-weight: bold;
        text-indent: 20px;
        // font-size: 20px;
        font-size: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .dv-charts-container {
        height: calc(100% - 50px);
    }
}
</style>
