<template>
<el-dialog width="800px" :visible.sync="visible" top="2vh" :before-close="()=>handleClose(false)" :close-on-click-modal="false" @open="dialogOpen">
    <div slot="title" class="dialog-title">{{dialogTitle}}</div>

    <el-form :model="formData" ref="ruleForm" :rules="rules" label-position="left" label-width="110px" v-if="visible">
        <el-form-item label="会议室地点" prop="name">{{row.name}}</el-form-item>

        <el-form-item label="会议日期" prop="date">
            <el-date-picker v-model="formData.date" type="date" :picker-options="pickerOptions" value-format="yyyy-MM-dd" :editable="false" :clearable="false" placeholder="请选择会议日期" size="small" />
        </el-form-item>

        <el-form-item label="会议时间" :rules="{required:true}">
            <el-row type="flex">
                <el-form-item>
                    <el-time-select v-model="formData.start_date" :picker-options="{start:'00:05', end:'23:55',minTime:currentTime, maxTime:formData.end_date, step: '00:05'}" prefix-icon="false" :editable="false" :clearable="false" size="small" style="width:95px" />
                </el-form-item>
                <span style="width:30px;text-align:center">至</span>
                <el-form-item>
                    <el-time-select v-model="formData.end_date" :picker-options="{start:'00:05', end:'23:55', minTime:formData.start_date, step: '00:05'}" prefix-icon="false" :editable="false" :clearable="false" size="small" style="width:95px" />
                </el-form-item>
            </el-row>
        </el-form-item>

        <el-form-item label="签到时间" :rules="{required:true}">
            <el-row type="flex">
                <el-form-item>
                    <el-time-select v-model="formData.sign_in_start_date" :picker-options="{start:'00:05', end:'23:55', maxTime:formData.sign_in_end_date, step: '00:05'}" prefix-icon="false" :editable="false" :clearable="false" size="small" style="width:95px" />
                </el-form-item>
                <span style="width:30px;text-align:center">至</span>
                <el-form-item>
                    <el-time-select v-model="formData.sign_in_end_date" :picker-options="{start:'00:05', end:'23:55', minTime:formData.sign_in_start_date,maxTime:formData.end_date, step: '00:05'}" prefix-icon="false" :editable="false" :clearable="false" size="small" style="width:95px" />
                </el-form-item>
            </el-row>
        </el-form-item>

        <el-form-item label="会议标题" prop="title">
            <el-input v-model="formData.title" maxlength="50" placeholder="请输入会议标题（50字内）" autocomplete="off" size="small" />
        </el-form-item>

        <el-form-item label="会议简介">
            <el-input v-model="formData.intro" type="textarea" :autosize="{ minRows: 3, maxRows: 4}" placeholder="请输入内容..." maxlength="500" show-word-limit autocomplete="off" size="small" />
        </el-form-item>

        <el-form-item label="参会人员" prop="userNames">
            <el-input v-model="formData.userNames" @click.native="handleChoose" @clear="getChooseUsers([])" placeholder="请选择参会人员" clearable maxlength="0" size="small" />
        </el-form-item>

    </el-form>
    <div slot="footer" align="center">
        <el-button class="footer-btn" :loading="submitLoading" type="primary" size="small" @click="submitForm('ruleForm')">保 存</el-button>
        <el-button class="footer-btn footer-btn-cancel" size="small" @click="handleClose(false)">取 消</el-button>
        <el-button v-if="!!row.id" class="footer-btn footer-btn-cancel" size="small" @click="handleDelete">取消会议</el-button>
    </div>

    <ChooseUsers :visible="userVisible" :values="userIds" @handleClose="getChooseUsers" />
</el-dialog>
</template>

<script>
import ChooseUsers from '../ChooseUsers'
import api from '@/api'
import dayjs from 'dayjs'

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        row: {
            type: Object,
            default: () => {},
        },
    },
    components: { ChooseUsers },
    data() {
        return {
            dialogTitle: "新增会议",
            submitLoading: false,
            userVisible: false,
            userIds: [],

            currentTime: "08:00",
            formData: {
                id: "",
                date: "",
                start_date: "09:00", // 会议开始时间
                end_date: "10:00", // 会议结束时间
                sign_in_start_date: "08:30", // 签到开始时间
                sign_in_end_date: "09:00", // 签到结束时间
                title: "", // 会议标题
                intro: "", // 会议简介
                userNames: "", // 参会人员名称
                userIds: [] // 参会人员Ids
            },

            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                },
            },

            rules: {
                date: [{ required: true, message: '会议日期不能为空' }],
                start_date: [{ required: true, message: '会议开始时间不能为空' }],
                end_date: [{ required: true, message: '会议结束时间不能为空' }],
                title: [{ required: true, message: '会议标题不能为空' }],
                userNames: [{ required: true, message: '参会人员不能为空' }],
            },
        };
    },

    methods: {
        // 开启选择成员弹窗
        handleChoose() {
            this.userVisible = true
        },

        // 关闭选择成员/获取选中的ids
        getChooseUsers(value) {
          this.userVisible = false
          if(!value) return false

          this.userIds = value
          const ids = value.map(p => p.id)
          const names = value.map(p => p.name)
          this.formData.userIds = ids
          this.formData.userNames = names.length <= 8 ? names.join('，') : `已选择${names.length}人`
        },

        // 取消会议
        handleDelete() {
            this.deleteCofirm('该操作将取消此会议, 是否继续?', 'warning', '确定')
                .then(async () => {
                    const res = await api.pass.meetCancel({ id: this.row.id });
                    if (res.code !== 0) return this.$message.error(res.message);
                    this.$message.success("取消会议成功");
                    this.handleClose(true)
                })
                .catch(() => {});
        },

        // 关闭弹出层
        handleClose(bool) {
            this.$emit("handleClose", bool);
            this.userIds = []
            this.currentTime = "08:00"
            this.formData = {
                id: "",
                date: "",
                start_date: "09:00", // 会议开始时间
                end_date: "10:00", // 会议结束时间
                sign_in_start_date: "08:30", // 签到开始时间
                sign_in_end_date: "09:00", // 签到结束时间
                title: "", // 会议标题
                intro: "", // 会议简介
                userNames: "", // 参会人员名称
                userIds: [] // 参会人员Ids
            }
        },

        // 提交新增
        submitForm(formName) {
            this.$refs[formName].validate(async valid => {
                if (valid) {
                    const formData = this.formData
                    const flag = !this.row.id
                    const params = {
                        id: formData.id,
                        meet_room_id: this.row.meet_room_id,
                        start_date: dayjs(`${formData.date} ${formData.start_date}`).valueOf(),
                        end_date: dayjs(`${formData.date} ${formData.end_date}`).valueOf(),
                        sign_in_start_date: dayjs(`${formData.date} ${formData.sign_in_start_date}`).valueOf(),
                        sign_in_end_date: dayjs(`${formData.date} ${formData.sign_in_end_date}`).valueOf(),
                        title: formData.title,
                        intro: formData.intro,
                        user_id: formData.userIds
                    }
                    this.submitLoading = true
                    const res = flag ? await api.pass.meetCreate(params) : await api.pass.meetUpdate(params)
                    this.submitLoading = false
                    if (res.code !== 0) return this.$message.error(res.message)
                    this.$message.success(`${this.dialogTitle}成功~`)
                    this.handleClose(true)
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },

        // 获取会议详情
        async getDetail(id) {
            const res = await api.pass.meetDetailQuery({ id })
            if (res.code !== 0) {
                this.$message.error(res.message)
                this.handleClose(false)
                return false
            }

            const data = res.data
            const formData = this.formData
            formData.date = dayjs(data.start_date).format('YYYY-MM-DD')
            formData.start_date = dayjs(data.start_date).format('HH:mm')
            formData.end_date = dayjs(data.end_date).format('HH:mm')
            formData.sign_in_start_date = dayjs(data.sign_in_start_date).format('HH:mm')
            formData.sign_in_end_date = dayjs(data.sign_in_end_date).format('HH:mm')

            formData.title = data.title
            formData.intro = data.intro
            const userIds = data.user_info.map(p => {
                return { id: p.user_id, name: p.user_name }
            })
            this.userIds = userIds
            const ids = userIds.map(p => p.id)
            const names = userIds.map(p => p.name)
            formData.userIds = ids
            formData.userNames = names.length <= 8 ? names.join('，') : `已选择${names.length}人`
        },

        // 打开弹框
        async dialogOpen() {
            const currentMinute = dayjs().minute()
            const num = 60 - currentMinute
            this.currentTime = dayjs().format('HH:mm')
            this.formData.start_date = dayjs().add(30, 'minutes').add(num, 'minutes').format('HH:mm')
            this.formData.end_date = dayjs().add(1, 'hour').add(num, 'minutes').format('HH:mm')
            this.formData.sign_in_start_date = dayjs().add(num, 'minutes').format('HH:mm')
            this.formData.sign_in_end_date = dayjs().add(30, 'minutes').add(num, 'minutes').format('HH:mm')

            if (this.row.id) {
                this.dialogTitle = '编辑会议'
                this.formData.id = this.row.id
                this.getDetail(this.row.id)
            } else {
                this.dialogTitle = '新增会议'
                this.formData.id = ""
            }

        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog .el-dialog__body {
    padding: 15px 30px !important;
}

// ::v-deep .el-input--prefix .el-input__inner {
//     padding-left: 10px;
// }

// ::v-deep .el-input--suffix .el-input__inner {
//     padding-right: 10px;
// }
</style>
