<template>
<div id="scroll-board">
    <dv-scroll-board :config="config" ref="scrollBoard" />
</div>
</template>

<script>
export default {
    name: 'ScrollBoard',
    props: ['tableData'],

    data() {
        return {
            config: {
                header: ['序号', '姓名', '状态', '签到时间'],
                data: [],
                // index: true,
                columnWidth: [80, 160, 140, 300],
                align: ['center'],
                rowNum: 16,
                headerBGC: '#1981f6',
                headerHeight: 45,
                oddRowBGC: 'rgba(0, 44, 81, 0.8)',
                evenRowBGC: 'rgba(10, 29, 50, 0.8)',
            },
        }
    },

    watch: {
        tableData(val, oldVal) {
            // console.log('Scroll val:', val)
            // console.log('Scroll oldVal:', oldVal)
            const list = val.map((item, i) => {
                const content = [i + 1, item.user_name, `<span style="color:${item.status === 0 ? 'gray' : item.status === 1 ? 'green' : 'orange'}">${item.state}</span>`, item.onTime]
                return content
            })
            this.$refs.scrollBoard.updateRows(list)
        }
    },

}
</script>

<style lang="scss">
#scroll-board {
    width: 30%;
    box-sizing: border-box;
    margin-left: 20px;
    height: 100%;
    overflow: hidden;
    padding-right: 1%;
}
</style>
