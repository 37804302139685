<template>
<div class="main">
    <el-container>
        <el-header>
            <el-form :model="searchForm" inline label-position="left" size="small" @submit.native.prevent>
                <el-form-item label="名称">
                    <el-input v-model="searchForm.name" size="small" placeholder="会议室名称" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" v-waves icon="el-icon-search" size="small" @click="onSubmit">查询</el-button>
                </el-form-item>

                <el-form-item class="float-right">
                    <el-button type="primary" @click="handleAdd">新增会议室</el-button>
                </el-form-item>
            </el-form>
        </el-header>

        <el-main>
            <el-table :header-cell-style="{ background: '#FAFAFA' }" :data="tableData" border stripe>
                <el-table-column prop="name" label="会议室名称" align="center" />

                <el-table-column label="签到设备" header-align="center" min-width="300" show-overflow-tooltip>
                    <template slot-scope="{row}">
                        <el-popover v-for="(item,i) in row.deviceList" :key="i" placement="top" width="160" v-model="item.deviceEdit">
                            <p>是否解绑该设备？</p>
                            <div style="text-align: right; margin: 0">
                                <el-button size="mini" type="text" @click="item.deviceEdit = false">取消</el-button>
                                <el-button type="primary" size="mini" @click="handleDeviceDelete(item, row.id)">确定</el-button>
                            </div>
                            <el-button type="danger" plain size="small" slot="reference" style="margin-right:6px">{{ item.deviceName }}</el-button>
                        </el-popover>

                        <el-select v-if="row.deviceAdd" v-model="selectValue" ref="saveTagInput" filterable placeholder="选择设备" size="small" @change="(value) => handleSelectChange(value, row.id)" @visible-change="(value) => handleSelectvisible(value, row)" class="selectTag">
                            <el-option v-for="p in deviceList" :key="p.value" :label="p.label" :value="p.value" />
                        </el-select>
                        <el-button v-else size="small" @click="showSelect(row)">+ 设备</el-button>
                    </template>
                </el-table-column>

                <el-table-column label="操作" align="center">
                    <template slot-scope="{row}">
                        <el-button type="text" @click="handleEdit(row)">编辑</el-button>
                        <el-button type="text" @click="handleDelete(row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <Pagination :pageIndex="pageIndex" :pageSize="pageSize" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" />
        </el-main>
    </el-container>
    <RoomEdit :visible="visible" :row="row" @handleClose="handleClose" />
</div>
</template>

<script>
import Pagination from '@/components/Pagination.vue'
import RoomEdit from '@/components/meeting/RoomEdit.vue'
import api from '../api'
export default {
    components: { Pagination, RoomEdit },
    data() {
        return {
            visible: false,
            row: {},

            searchForm: {
                name: '',
            },
            tableData: [], // 列表数据
            total: 0, // 总条数
            pageIndex: 1, // 起始页码
            pageSize: 10, // 每页条数

            deviceList: [], // 可选择的设备列表
            selectValue: '' // +绑定设备时  选择的设备id
        }
    },
    methods: {
        // 分页器
        handleCurrentChange(val) {
            this.pageIndex = val
            this.getList()
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.getList()
        },

        // 查询
        onSubmit() {
            this.pageIndex = 1
            this.getList()
        },

        // 新增
        handleAdd() {
            this.visible = true
        },

        // 编辑
        handleEdit(row) {
            this.row = row
            this.visible = true
        },
        handleClose(bool) {
            this.visible = false
            this.row = {}
            if (bool) this.getList()
        },

        // 删除
        handleDelete(row) {
            this.deleteCofirm('该操作将删除此会议室, 是否继续?')
                .then(async () => {
                    const res = await api.pass.meetRoomDelete({ id: row.id });
                    if (res.code !== 0) return this.$message.error(res.message);
                    this.$message.success("删除成功");
                    this.getList()
                })
                .catch(() => {});
        },

        // 解绑设备
        async handleDeviceDelete(item, id) {
            const res = await api.pass.meetRoomUnbindDevice({ id, device_id: item.deviceId })
            if (res.code !== 0) {
                this.$message.error(res.message)
            } else {
                this.$message.success("解绑成功~")
                this.getList()
            }
            item.deviceEdit = false
        },

        // 点击新增按钮  显示下拉框
        async showSelect(row) {
            row.deviceAdd = true
            await this.getDeviceList()
            this.$nextTick(() => {
                this.$refs.saveTagInput.toggleMenu()
            });
        },

        // 下拉框选中事件(设备绑定)
        async handleSelectChange(value, id) {
            const res = await api.pass.meetRoomBindDevice({ id, device_id: value, })
            if (res.code !== 0) {
                this.$message.error(res.message)
            } else {
                this.$message.success("绑定成功~")
                this.getList()
            }
        },

        // 下拉选项开启和关闭
        handleSelectvisible(value, row) {
            if (!value) {
                row.deviceAdd = false
                this.selectValue = '';
            }
        },

        // 获取设备列表
        async getDeviceList() {
            try {
                const res = await api.pass.meetDeviceQuery();
                if (res.code !== 0) this.$message.error(res.message);
                const list = res.data || []
                this.deviceList = list.map(item => {
                    return {
                        label: item.device_name,
                        value: item.id
                    }
                }).sort((a, b) => a.label.localeCompare(b.label))
            } catch (error) {
                console.log(error)
            }

        },

        // 请求列表
        async getList() {
            try {
                const res = await api.pass.meetRoomQuery({
                    name: this.searchForm.name,
                    page_index: this.pageIndex,
                    page_size: this.pageSize,
                    sort_name: 'name',
                    sort_order: 'asc'
                });
                if (res.code !== 0) this.$message.error(res.message);
                const list = res.data.list || []
                this.tableData = list.map(item => {
                    item.deviceAdd = false
                    item.deviceList = item.device_info.map(p => {

                        return {
                            deviceEdit: false,
                            deviceId: p.device_id,
                            deviceName: p.device_name
                        }
                    })
                    return item
                })
                this.total = res.data.total
            } catch (error) {
                console.log(error)
            }

        },
    },

    async mounted() {
        await this.getDeviceList()
        await this.getList()
    }
}
</script>

<style lang="scss" scoped>
</style>
