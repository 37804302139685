<template>
  <el-dialog
    v-dialogDrag
    width="850px"
    :visible.sync="visible"
    top="2vh"
    :before-close="() => handleClose(false)"
    :close-on-click-modal="false"
    @open="dialogOpen"
  >
    <div
      slot="title"
      class="dialog-title"
    >信息登记</div>

    <el-form
      :model="formData"
      ref="ruleForm"
      :rules="rules"
      label-position="left"
      label-width="92px"
      size="small"
    >
      <p class="form-title">访客信息</p>
      <el-row
        type="flex"
        :gutter="40"
      >
        <el-col :span="14">
          <el-form-item
            label="访客姓名"
            prop="user_name"
          >
            <el-input
              v-model="formData.user_name"
              maxlength="15"
              placeholder="请输入访客姓名"
              autocomplete="off"
              size="small"
              disabled
            />
          </el-form-item>

          <el-form-item
            label="性别"
            prop="gender"
          >
            <el-radio-group
              v-model="formData.gender"
              disabled
            >
              <el-radio :label="1">男</el-radio>
              <el-radio :label="2">女</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item
            label="体温"
            prop="temperature"
          >
            <el-input-number
              v-model="formData.temperature"
              :step="0.1"
              :min="35"
              :max="42"
              :controls="false"
              step-strictly
              size="small"
              style="width:88px;margin-right:10px"
              disabled
            />℃
          </el-form-item>
          <el-form-item
            label="身份证号"
            prop="id_number"
          >
            <el-input
              v-model="formData.id_number"
              placeholder="请输入身份证号码"
              maxlength="18"
              show-word-limit
              size="small"
              disabled
            />
          </el-form-item>
          <el-form-item
            label="手机号"
            prop="phone_number"
          >
            <el-input
              v-model="formData.phone_number"
              placeholder="访客手机号"
              maxlength="11"
              show-word-limit
              size="small"
            />
          </el-form-item>


          <p class="form-title">被访人信息</p>

          <el-form-item
            label="被访人"
            prop="interviewed_user_id"
          >
            <el-select
              v-model="formData.interviewed_user_id"
              filterable
              remote
              placeholder="请输入关键词"
              :remote-method="remoteMethod"
              :loading="userloading"
              value-key="user_id"
              name="nick_name"
            >
              <el-option
                v-for="item in options"
                :key="item.user_id"
                :label="item.nick_name"
                :value="item.user_id"
              />
            </el-select>

            <el-button
              type="primary"
              @click="handleSelect"
            >从部门结构中选</el-button>
          </el-form-item>
          <p class="form-title">其它信息</p>
          <el-form-item
            label="来访原因"
            prop="reason_option"
          >
            <el-radio-group v-model="formData.reason_option">
              <el-radio
                v-for="item in reasonOptionList"
                :key="item.value"
                :label="item.value"
              >{{item.label}}</el-radio>
            </el-radio-group>

          </el-form-item>
          <el-form-item
            label=" "
            prop="reason"
            v-if="showDetail"
          >
            <el-input
              v-model="formData.reason"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
              placeholder="请输入内容..."
              maxlength="50"
              show-word-limit
              autocomplete="off"
              size="small"
            />
          </el-form-item>
          <el-form-item
            label="现场电话确认"
            label-width="100px"
            prop="confirm"
          >
            <el-radio-group v-model="formData.confirm">
              <el-radio :label="1">已确认</el-radio>
              <el-radio :label="0">未确认</el-radio>
            </el-radio-group>
          </el-form-item>

        </el-col>

        <el-col :span="10">
          <el-form-item
            label
            label-width="0"
          >
            <img
              :src="row.path"
              style="width:305px;max-height:460px;border-radius:8px;"
            />
            <div style="text-align:center;">访客现场抓拍</div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div
      slot="footer"
      align="center"
    >
      <el-button
        class="footer-btn"
        :loading="submitLoading"
        type="primary"
        size="small"
        @click="submitForm('ruleForm')"
      >保 存</el-button>
      <el-button
        class="footer-btn footer-btn-cancel"
        size="small"
        @click="handleClose(false)"
      >取 消</el-button>
    </div>
    <ChooseUsers
      :visible="userVisible"
      :values="[]"
      :isMutiple="false"
      :isAll="true"
      @handleClose="handleUserClose"
    />
  </el-dialog>
</template>

<script>
import api from '../../api'
import ChooseUsers from '../ChooseUsers'
const checkPhone = (rule, value, callback) => {
  if (value) {
    const reg = /^1[2|3|4|5|6|7|8|9][0-9]\d{8}$/
    if (reg.test(value)) {
      callback()
    } else {
      return callback(new Error('请输入正确的手机号'))
    }
  } else {
    return callback()
  }
}
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Object,
      default: () => {},
    },
  },
  components: { ChooseUsers },
  data() {
    return {
      submitLoading: false,
      userVisible: false,
      userDialogData: [],
      reasonOptionList: [
        {
          label: '开会',
          value: '开会',
        },
        {
          label: '办事',
          value: '办事',
        },
        {
          label: '家长来访',
          value: '家长来访',
        },
        {
          label: '其它',
          value: '其它',
        },
      ],

      formData: {
        user_name: '',
        gender: 1,
        temperature: '',
        phone_number: '',
        id_number: '',
        reason: '',
        reason_option: '',
        interviewed_user_id: '',
        confirm: 1,
      },

      rules: {
        // user_name: [{ required: true, message: '请填写访客姓名' }],
        // temperature: [{ required: true, message: '请填写访客体温' }],
        phone_number: [
          { required: true, message: '请填写手机号' },
          { validator: checkPhone, trigger: 'blur' },
        ],
        // id_number: [
        //     { required: false, message: "请填写身份证号码", trigger: "blur" },
        //     { pattern: /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|[xX])$/, message: "请使用正确的身份证号码" },
        // ],
        interviewed_user_id: [{ required: true, message: '请选择被访人' }],
      },

      options: [],
      list: [],
      userloading: false,
    }
  },
  computed: {
    showDetail() {
      return this.formData.reason_option === '其它'
    },
  },

  methods: {
    handleSelect() {
      this.userVisible = true
    },
    handleUserClose(data) {
      this.userVisible = false
      if (data.length > 0) {
        this.options = [
          {
            user_id: data[0].id,
            nick_name: data[0].name,
          },
        ]
        this.formData.interviewed_user_id = data[0].id
      }
    },
    // 关闭弹出层
    handleClose(bool) {
      this.$refs.ruleForm.resetFields()
      this.$emit('handleClose', bool)
      this.options = []
    },
    async remoteMethod(query) {
      if (query !== '') {
        this.userloading = true
        const res = await api.common.getAllUserByDept()
        this.userloading = false
        const data = res.data || []
        this.options = data.filter((item) => {
          return item.nick_name.toLowerCase().indexOf(query.toLowerCase()) > -1
        })
      } else {
        this.options = []
      }
    },
    // 提交新增
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {

        if (valid) {
          this.submitLoading = true
          api.pass
            .visitorUpdate({
              confirm: this.formData.confirm,
              gender: this.formData.gender,
              id: this.row.id,
              id_number: this.formData.id_number,
              interviewed_user_id: this.formData.interviewed_user_id,
              phone_number: this.formData.phone_number,
              reason: this.showDetail
                ? this.formData.reason
                : this.formData.reason_option,
              temperature: this.formData.temperature + '',
              user_name: this.formData.user_name,
            })
            .then((res) => {
              if (res.code !== 0) this.$message.error(res.message)
              this.submitLoading = false
              this.handleClose(true)
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },

    // 打开弹框
    dialogOpen() {
      const obj = {
        reason_option: '',
        user_name: this.row.user_name,
        gender: this.row.gender,
        temperature: this.row.temperature,
        phone_number: this.row.phone_number,
        id_number: this.row.id_number,
        reason: this.row.reason,
        interviewed_user_id: this.row.interviewed_user_id,
        confirm: this.row.confirm,
      }
      if (
        this.reasonOptionList.find((item) => item.value === this.row.reason)
      ) {
        obj.reason_option = this.row.reason
      } else {
        obj.reason_option = '其它'
      }

      this.formData = obj

      if (this.row.interviewed_user_id) {
        this.options = [
          {
            user_id: this.row.interviewed_user_id,
            nick_name: this.row.interviewed_user_name,
          },
        ]
      }

      this.$refs.ruleForm && this.$refs.ruleForm.resetFields()
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog .el-dialog__body {
  padding: 15px 30px !important;
}

.form-title {
  font-size: 16px;
  font-weight: 600;
  color: black;
}

// ::v-deep .el-input--prefix .el-input__inner {
//     padding-left: 10px;
// }

// ::v-deep .el-input--suffix .el-input__inner {
//     padding-right: 10px;
// }
</style>
