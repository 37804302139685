<template>
<div class="main">
    <el-tabs v-model="activeName">
        <el-tab-pane label="会议室管理" name="1">
            <RoomManage v-if="activeName ==='1'" />
        </el-tab-pane>
        <el-tab-pane label="会议预定" name="2">
            <MeetingManage v-if="activeName ==='2'" />
        </el-tab-pane>
        <el-tab-pane label="会议统计" name="3">
            <MeetingStatistics v-if="activeName ==='3'" />
        </el-tab-pane>
    </el-tabs>
</div>
</template>

<script>
import RoomManage from './RoomManage.vue'
import MeetingManage from './MeetingManage.vue'
import MeetingStatistics from './MeetingStatistics.vue'

export default {
    components: { RoomManage, MeetingManage, MeetingStatistics },
    data() {
        return {
            activeName: "1",
        }
    },

    mounted() {
        const name = this.$route.params.name;
        console.log(this.$route.params)

        if (name) this.activeName = name;
        console.log(this.activeName)
    }
}
</script>

<style lang="scss" scoped>
</style>
