<template>
<el-dialog width="800px" :visible.sync="visible" top="2vh" :before-close="()=>handleClose(false)" :close-on-click-modal="false" @open="dialogOpen">
    <div slot="title" class="dialog-title">{{dialogTitle}}</div>

    <el-form :model="formData" ref="ruleForm" :rules="rules" label-width="100px">
        <el-form-item label="会议室名称" prop="name">
            <el-input v-model="formData.name" ref="input" placeholder="请输入区域名称（15字内）" autocomplete="off" style="width:98%" />
        </el-form-item>
        <el-form-item label="会议室序号">
            <el-input-number v-model="formData.sort" :step="1" :min="0" :max="999" step-strictly style="width:98%" />
        </el-form-item>
    </el-form>
    <div slot="footer" align="center">
        <el-button class="footer-btn" :loading="submitLoading" type="primary" size="small" @click="submitForm('ruleForm')">保 存</el-button>
        <el-button class="footer-btn footer-btn-cancel" size="small" @click="handleClose(false)">取 消</el-button>
    </div>
</el-dialog>
</template>

<script>
import api from '@/api'
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        row: {
            type: Object,
            default: () => {},
        },

    },

    data() {
        return {
            dialogTitle: "新增会议室",
            submitLoading: false,

            formData: {
                id: "",
                name: "",
                sort: 0
            },

            rules: {
                name: [
                    { required: true, message: '会议室名称不能为空' },
                    { min: 2, max: 16, message: '长度在2到15个字符之间' }
                ],
            },

        };
    },

    methods: {
        // 关闭弹出层
        handleClose(bool) {
            this.formData.id = ""
            this.formData.sort = 0
            this.formData.name = ""
            this.$emit("handleClose", bool);
        },

        // 提交新增
        submitForm(formName) {
            this.$refs[formName].validate(async valid => {
                if (valid) {
                    this.submitLoading = true
                    const flag = !this.formData.id
                    const res = flag ? await api.pass.meetRoomCreate(this.formData) : await api.pass.meetRoomUpdate(this.formData)
                    this.submitLoading = false
                    if (res.code !== 0) return this.$message.error(res.message)
                    this.$message.success(`${this.dialogTitle}成功~`)
                    this.handleClose(true)
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },

        // 打开弹框
        async dialogOpen() {
            if (this.row.id) {
                this.formData.id = this.row.id
                this.dialogTitle = '编辑会议室'
            } else {
                this.dialogTitle = '新增会议室'
            }
            this.formData.name = this.row.name || ""
            this.formData.sort = this.row.sort || 0
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
