<template>
    <div class="main">
        <el-container>
            <el-header>
                <el-form :model="searchForm" inline label-position="left" size="small" @submit.native.prevent>
                    <el-form-item label="来访时间">
                        <el-date-picker
                            v-model="searchForm.dateTimes"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            :default-time="['00:00:00', '23:59:59']"
                            :clearable="false"
                        />
                    </el-form-item>
                    <el-form-item label="访客姓名">
                        <el-input
                            v-model="searchForm.userName"
                            size="small"
                            placeholder="请输入访客姓名"
                            clearable
                        />
                    </el-form-item>
                    <el-form-item>
                        <el-button
                            type="primary"
                            icon="el-icon-search"
                            size="small"
                            @click="onSubmit"
                        >查询</el-button>
                    </el-form-item>
                </el-form>
            </el-header>

            <el-main>
                <el-table
                    :header-cell-style="{ background: '#FAFAFA' }"
                    :data="tableData"
                    border
                    stripe
                >
                    <el-table-column prop="user_name" label="访客姓名" align="center" width="100" />

                    <el-table-column prop="gender" label="性别" align="center" width="60">
                        <template slot-scope="{row}">
                            <span v-if="row.gender">{{ row.gender === 1 ? '男' : '女' }}</span>
                            <span v-else>未知</span>
                        </template>
                    </el-table-column>

                    <el-table-column prop="temperature" label="体温(℃)" align="center" width="75" />

                    <el-table-column prop="id_number_str" label="身份证号" align="center" width="185" />

                    <el-table-column prop="phone_number" label="手机号" align="center" width="120" />

                    <el-table-column
                        prop="identify_time_str"
                        label="来访时间"
                        align="center"
                        width="100"
                    />

                    <el-table-column prop="leave_time_str" label="离开时间" align="center" width="100">
                        <template slot-scope="{row}">
                            <span v-if="row.leave">{{ row.leave_time_str }}</span>
                            <span v-else>--</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="device_name" label="登记设备" align="center" />
                    <el-table-column label="登记拍照" align="center" width="100">
                        <template slot-scope="{row}">
                            <el-button type="text" @click="showPhoto(row)" v-if="row.path">查看</el-button>
                            <span v-else>暂无</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="reason"
                        label="来访原因"
                        align="center"
                        min-width="100"
                        show-overflow-tooltip
                    />
                    <el-table-column prop="interviewed_user_name" label="被访人" align="center" />

                    <el-table-column prop="confirm" label="被访人电话确认" align="center">
                        <template slot-scope="{row}">{{ row.confirm === 1 ? '已确认' : '未确认' }}</template>
                    </el-table-column>

                    <el-table-column label="操作" align="center">
                        <template slot-scope="{row}">
                            <el-button type="text" @click="handleEdit(row)">登记信息</el-button>
                            <el-button
                                type="text"
                                @click="handleLeave(row)"
                                :disabled="row.leave === 1"
                            >离校确认</el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <Pagination
                    :pageIndex="pageIndex"
                    :pageSize="pageSize"
                    :total="total"
                    @handleSizeChange="handleSizeChange"
                    @handleCurrentChange="handleCurrentChange"
                />
            </el-main>
        </el-container>
        <VisitorRecordEdit :visible="visible" :row="row" @handleClose="handleClose" />

        <el-dialog width="800px" :visible.sync="photoVisible" top="2vh">
            <div style="text-align:center;padding:0 0 30px;">
                <img :src="photoSrc" style="max-width:100%;" />
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Pagination from '@/components/Pagination.vue'
import VisitorRecordEdit from '@/components/visitor/VisitorRecordEdit.vue'
import api from '../api'
import dayjs from 'dayjs'

export default {
    components: { Pagination,VisitorRecordEdit },
    data() {
        return {
            visible: false,
            row: {},

            searchForm: {
                userName: "",
                dateTimes: [
                    dayjs()
                        .subtract(1, 'day')
                        .startOf('day')
                        .format('YYYY-MM-DD HH:mm:ss'),
                    dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
                ],
            },
            tableData: [], // 列表数据
            total: 0, // 总条数
            pageIndex: 1, // 起始页码
            pageSize: 10, // 每页条数
            photoVisible: false,
            photoSrc: ''
        }
    },
    methods: {

        showPhoto(row) {
            this.photoSrc = row.path
            this.photoVisible = true
        },
        // 分页器
        handleCurrentChange(val) {
            this.pageIndex = val
            this.getList()
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.getList()
        },

        // 查询
        onSubmit() {
            this.pageIndex = 1
            this.getList()
        },

        // 新增
        handleAdd() {
            this.visible = true
        },

        // 编辑
        handleEdit(row) {
            this.row = row
            this.visible = true
        },

        handleLeave(row) {
            api.pass.visitorUpdate(Object.assign({}, row, { leave: 1 })).then(res => {
                if (res.code === 0) {
                    this.$message.success('操作成功！')
                } else {
                    this.$message.error('操作失败！入校时登记信息不完整，请补充。')
                }
            })
        },

        handleClose(bool) {
            this.visible = false
            this.row = {}
            if (bool) this.getList()
        },
        // 请求列表
        getList() {
            api.pass
                .visitorLogs({
                    user_name: this.searchForm.userName,
                    page_index: this.pageIndex,
                    page_size: this.pageSize,
                    end_time: dayjs(this.searchForm.dateTimes[1]).valueOf(),
                    start_time: dayjs(this.searchForm.dateTimes[0]).valueOf(),
                })
                .then((res) => {
                    if (res.code !== 0) this.$message.error(res.message);
                    const data = res.data.list || []

                    data.forEach(item => {
                        item.identify_time_str = dayjs(item.identify_time).format("YYYY-MM-DD HH:mm:ss")
                        item.leave_time_str = dayjs(item.leave_time).format("YYYY-MM-DD HH:mm:ss")
                        item.id_number_str = item.id_number.replace(/^(.{4})(?:\d+)(.{4})$/,"$1******$2")
                    })


                    this.tableData = data
                    this.total = res.data.total
                })


        },
    },

    async mounted() {
        this.getList()
    }
}
</script>

<style lang="scss">
</style>
