<template>
<div>
    <datav />
</div>
</template>

<script>
import datav from '../components/datav/index.vue'

export default {
    components: {
        datav
    },
}
</script>

<style lang="scss" scoped>

</style>
