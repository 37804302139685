<template>
  <div class="main">
    <el-container>
      <el-header>
        <el-form :model="searchForm" inline label-position="left" size="small" @submit.native.prevent>
          <el-form-item label="名称">
            <el-input v-model="searchForm.name" size="small" placeholder="设备名称" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" v-waves icon="el-icon-search" size="small" @click="onSearch">查询</el-button>
          </el-form-item>
          <el-form-item class="float-right">
            <el-input placeholder="请输入IP" v-model="ip" class="find-by-ip">
              <el-button slot="append" size="small" icon="el-icon-search" @click="onFind">发现设备</el-button>
            </el-input>

            <el-button icon="el-icon-setting" size="small" @click="onPushUser">推送人员信息</el-button>
            <el-button size="small" @click="handleLog">设备日志</el-button>
          </el-form-item>
        </el-form>
      </el-header>

      <el-main>
        <el-table :header-cell-style="{background:'#FAFAFA'}" :data="tableData" stripe>
          <el-table-column prop="device_name" label="设备名称" />

          <el-table-column prop="device_serial_no" label="设备编号" width="180" />

          <el-table-column prop="device_ip" label="设备IP" width="180" />
          <el-table-column prop="device_type" label="设备类型">
            <template slot-scope="{row}">
              <span v-if="row.device_type === 1">考勤</span>
              <span v-else-if="row.device_type === 2">门禁</span>
              <span v-else-if="row.device_type === 255">访客</span>
              <span v-else-if="row.device_type == 4">会议</span>
              <span v-else-if="row.device_type == 8">测温考勤</span>
              <span v-else>未知</span>
            </template>
          </el-table-column>
          <el-table-column prop="device_user_count" label="设备人员" />
          <el-table-column prop="device_face_count" label="设备识别照" />
          <!-- <el-table-column prop="create_time" label="创建时间" /> -->
          <el-table-column prop="device_state" label="设备状态">
            <template slot-scope="{row}">
              <span v-if="row.device_state">在线</span>
              <span v-else style="color:#ff9c47;">离线</span>
            </template>
          </el-table-column>

          <el-table-column label="操作" width="190">
            <template slot-scope="scope">
              <el-button size="small" @click="handleEdit(scope.$index, scope.row)">设备设置</el-button>
            </template>
          </el-table-column>
        </el-table>

        <Pagination
          :pageIndex="pageIndex"
          :pageSize="pageSize"
          :total="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        />
      </el-main>
    </el-container>
    <DeviceSetting :visible="visible" :row="dialogData" @handleClose="handleClose" />

    <DeviceLog :visible="logVisible" :row="logDialogData" @handleClose="handleLogClose" />
  </div>
</template>

<script>
import api from '../api'
import {timestampToDateStr} from '@/utils'
import DeviceSetting from '../components/passManage/DeviceSetting.vue'
import DeviceLog from '../components/passManage/DeviceLog.vue'
import Pagination from '../components/Pagination.vue'
export default {
  components: { DeviceSetting, DeviceLog, Pagination },
  data() {
    return {
      searchForm: {
        name: '',
      },
      visible: false,
      dialogData: { id: '' },
      tableData: [],
      total: 0,
      pageIndex: 1,
      pageSize: 10,

      logVisible: false,
      logDialogData: { id: '' },
      ip: ''
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      api.pass
        .getDevice({
          device_name: this.searchForm.name,
          device_state: null,
          page_index: this.pageIndex,
          page_size: this.pageSize,
          sort_name: 'device_name',
          sort_order: 'asc',
        })
        .then((res) => {
          if (res.code !== 0) this.$message.error(res.message);
          const data = res.data.list || []
          data.forEach((item) => {
            item.create_time = timestampToDateStr(item.created_at,'YYYY-MM-DD')
          })
          this.tableData = data
          this.total = res.data.total
        })
    },
    onSearch() {
      this.pageIndex = 1
      this.getData()
    },
    onFind() {
      const params = {}
      if (this.ip.length > 0) {
        params.ip = this.ip
      }
      api.pass.findDevice(params).then(res=>{
        if (res.code !== 0) this.$message.error(res.message);
        this.$message.success("发现设备指令发送成功")
      })
    },
    onPushUser() {
      api.common.pushUserToDevice({}).then(res=>{
        if (res.code !== 0) this.$message.error(res.message);
        this.$message.success("推送人员信息指令发送成功")
      })
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getData()
    },
    handleCurrentChange(val) {
      this.pageIndex = val
      this.getData()
    },
    handleClose(b) {
      this.visible = false
      if (b) {
        this.getData()
      }
    },
    handleEdit(index, row) {
      this.visible = true
      this.dialogData = row
    },
    handleLogClose(b) {
      this.logVisible = false
    },
    handleLog(index, row) {
      this.logVisible = true
    },
  },
}
</script>

<style lang="scss" scoped>
.find-by-ip {
  width: 220px;
  margin: 0 10px 0 0;
}
</style>
