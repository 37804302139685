<template>
  <div class="main">
    <el-container>
      <el-header>
        <el-form :model="searchForm" inline label-position="left" size="small" @submit.native.prevent>
          <el-form-item label="时间范围">
            <el-date-picker
              v-model="searchForm.dateTimes"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              :clearable="false"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="设备">
            <el-select v-model="searchForm.deviceID" placeholder="请选择" value-key="id" clearable>
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.device_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" v-waves icon="el-icon-search" size="small" @click="onSearch">查询</el-button>
          </el-form-item>
        </el-form>
      </el-header>

      <el-main>
        <el-table :header-cell-style="{background:'#FAFAFA'}" :data="tableData" stripe>
          <el-table-column prop="identify_time_str" label="通行时间" width="180" />

          <el-table-column prop="device_name" label="通行设备" />
          <el-table-column prop="device_name" label="通行类型">
            <template slot-scope="{row}">{{ models[row.model] }}</template>
          </el-table-column>
          <el-table-column prop="temperature" label="温度 ℃">
            <template slot-scope="{row}">{{ row.temperature || '-' }}</template>
          </el-table-column>

          <el-table-column label="现场拍照">
            <template slot-scope="{row}">
              <el-button type="text" @click="showPhoto(row)" v-if="row.path">查看</el-button>
              <span v-else>暂无拍照</span>
            </template>
          </el-table-column>
        </el-table>

        <Pagination
          :pageIndex="pageIndex"
          :pageSize="pageSize"
          :total="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        />
      </el-main>
    </el-container>

    <el-dialog width="800px" :visible.sync="photoVisible" top="2vh">
      <div style="text-align:center;padding:0 0 30px;">
        <img :src="photoSrc" style="max-width:100%;" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from '../api'
import dayjs from 'dayjs'
import Pagination from '../components/Pagination.vue'
export default {
  name: 'passLog',
  components:{Pagination},
  data() {
    return {
      models: ['刷脸', '人像&卡双重验证', '人证比对', '刷卡', '开门按钮开门', '远程开门', '密码开门', '人像&密码双重验证', '口罩检测', '指纹比对'],
      drawer: false,
      searchForm: {
        dateTimes: [
          dayjs()
            .startOf('day')
            .format('YYYY-MM-DD HH:mm:ss'),
          dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        ],
        deviceID: '',
      },
      options: [],
      value: '',
      tableData: [],
      total: 0,
      pageIndex: 1,
      pageSize: 10,
      photoVisible: false,
      photoSrc: '',
    }
  },
  mounted() {
    this.getData()
    this.getAllDevice()
  },
  methods: {
    showPhoto(row) {
      this.photoSrc = row.path
      this.photoVisible = true
    },
    getData() {
      api.pass
        .strangerLogs({
          device_id: this.searchForm.deviceID,
          end_time: dayjs(this.searchForm.dateTimes[1]).valueOf(),
          start_time: dayjs(this.searchForm.dateTimes[0]).valueOf(),
          page_index: this.pageIndex,
          page_size: this.pageSize,
        })
        .then((res) => {
          if (res.code !== 0) this.$message.error(res.message);
          if (res.data.list) {
            res.data.list.forEach((item) => {
              item.identify_time_str = dayjs(item.identify_time).format(
                'YYYY-MM-DD HH:mm:ss'
              )
            })
            this.tableData = res.data.list
            this.total = res.data.total
          } else {
            this.tableData = []
            this.total = 0
          }
        })
    },
    getAllDevice() {
      api.pass.getAllDevice({}).then((res) => {
        if (res.code !== 0) this.$message.error(res.message);
        this.options = res.data
      })
    },
    onSearch() {
      this.pageIndex = 1
      this.getData()
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getData()
    },
    handleCurrentChange(val) {
      this.pageIndex = val
      this.getData()
    },
    handleClose() {
      this.drawer = !this.drawer
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
