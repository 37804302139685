<template>
  <el-dialog
    width="888px"
    :visible.sync="visible"
    :before-close="handleClose"
    :close-on-click-modal="false"
    @open="openDialog"
    top="2vh"
    append-to-body
  >
    <div
      slot="title"
      class="title"
    >选择设备</div>

    <el-transfer
      v-model="value"
      v-loading="loading"
      :props="props"
      filterable
      :titles="['可选设备', '已选设备']"
      :button-texts="['移除', '选择']"
      :format="{noChecked:'${total}',hasChecked:'${checked}/${total}'}"
      :data="tableData"
    >
      <div
        slot-scope="{ option }"
        class="userinfo"
      >
        {{option.device_name}}
      </div>
    </el-transfer>

    <el-row
      slot="footer"
      align="end"
      style="margin-right:15px"
    >
      <el-col>
        <el-button
          plain
          @click="handleClose"
        >取 消</el-button>
        <el-button
          type="primary"
          @click="handleSubmit"
        >确 定</el-button>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import api from '../api'

export default {
  props: ['visible', 'values', 'deviceType'],
  data() {
    return {
      props: {
        key: 'id',
        label: 'device_name',
      },
      tableData: [], // 设备列表数据
      value: [], // 右边的值

      loading: false,
    }
  },

  methods: {
    // 提交
    handleSubmit() {
      const list = []
      this.tableData.forEach((item) => {
        if (this.value.indexOf(item.id) > -1) {
          list.push(item)
        }
      })
      this.$emit('handleEmit', list)
    },

    // 关闭
    handleClose() {
      this.$emit('handleEmit', false)
    },

    // 获取设备列表
    async getMachinesList() {
      this.loading = true
      const data = await api.pass.getAllDevice({})
      this.loading = false
      if (data.code !== 0) {
        this.$message.error(data.message)
      } else {
        this.tableData = data.data.sort((a, b) =>
          a.device_name.localeCompare(b.device_name)
        )
      }
    },

    // 打开选择成员的时候做的事情
    openDialog() {
      this.value = this.values
      this.getMachinesList()
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

::v-deep.el-dialog__header {
  padding: 15px 20px 10px 20px;
}

.userinfo {
  display: flex;

  span {
    display: block;
  }

  .name {
    width: 155px !important;
    font-weight: 600;
    margin-right: 15px;
  }

  .deviceType {
    width: 100px;
    // color: gray;
    margin-right: 16px;
  }

  .machineStatus {
    max-width: 100px;
    // color: gray;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// 穿梭框的宽度
::v-deep .el-transfer-panel {
  width: 310px;
}

::v-deep .el-transfer-panel__body {
  height: 550px;
}

::v-deep .el-transfer-panel__list.is-filterable {
  height: 505px;
}

::v-deep .el-transfer__buttons {
  padding: 0 8px;
}
</style>
