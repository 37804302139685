<template>
  <div class="main">
    <el-container>
      <el-header>
        <el-form :model="searchForm" inline label-position="left" size="small" @submit.native.prevent>
          <el-form-item label="名称">
            <el-input v-model="searchForm.name" clearable size="small" placeholder="规则名称"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" v-waves icon="el-icon-search" size="small" @click="onSearch">查询</el-button>
          </el-form-item>

          <el-form-item class="float-right">
            <el-button type="primary" @click="visible = true">新增规则</el-button>
          </el-form-item>
        </el-form>
      </el-header>

      <el-main>
        <el-table :header-cell-style="{background:'#FAFAFA'}" :data="tableData" stripe>
          <el-table-column prop="name" label="区域通行规则名称" />

          <el-table-column label="可通行人员">
            <template slot-scope="{row}">
              <el-button type="text" @click="handlePassUser(row)">{{ row.user_count }}</el-button>
            </template>
          </el-table-column>

          <el-table-column label="可通行设备">
            <template slot-scope="{row}">
              <el-button type="text" @click="handlePassDevice(row)">{{ row.device_count }}</el-button>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button size="small" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>

              <el-button size="small" @click="handleDel(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <Pagination
          :pageIndex="pageIndex"
          :pageSize="pageSize"
          :total="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        />
      </el-main>
    </el-container>

    <RuleSetting :visible="visible" :row="dialogData" @handleClose="handleClose" />

    <ChooseDevices
      :visible="deviceVisible"
      :values="deviceDialogData"
      @handleEmit="handleDeviceClose"
    />

    <ChooseUsers :visible="userVisible" :values="userDialogData" @handleClose="handleUserClose" />
  </div>
</template>

<script>
import api from '../api'
import RuleSetting from '../components/passManage/RuleSetting'
import ChooseDevices from '../components/ChooseDevices'
import ChooseUsers from '../components/ChooseUsers'
import Pagination from '../components/Pagination.vue'
export default {
  components: { RuleSetting, ChooseDevices, ChooseUsers, Pagination },
  data() {
    return {
      visible: false,
      dialogData: {},
      searchForm: {
        name: '',
      },
      tableData: [],
      total: 0,
      pageIndex: 1,
      pageSize: 10,
      deviceDialogData: [],
      deviceVisible: false,
      userDialogData: [],
      userVisible: false,
      currentId: '',
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      api.pass
        .getRuleList({
          name: this.searchForm.name,
          page_index: this.pageIndex,
          page_size: this.pageSize,
          sort_name: 'name',
          sort_order: 'asc',
        })
        .then((res) => {
          if (res.code !== 0) this.$message.error(res.message);
          this.tableData = res.data.list
          this.total = res.data.total
        })
    },
    onSearch() {
      this.pageIndex = 1
      this.getData()
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getData()
    },
    handleCurrentChange(val) {
      this.pageIndex = val
      this.getData()
    },
    handleClose(b) {
      this.visible = false
      this.dialogData = {}
      if (b) {
        this.getData()
      }
    },
    handleDel(row) {
      this.$confirm('确定删除么?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          api.pass.deleteRule({ id: row.id }).then((res) => {
            if (res.code !== 0) this.$message.error(res.message);
            this.$message.success('删除成功!')
            this.getData()
          })
        })
        .catch(() => {
          this.$message.info('已取消删除')
        })
    },
    handleEdit(index, row) {
      this.visible = true
      this.dialogData = row
    },
    handlePassDevice(row) {
      api.pass
        .queryRuleDevice({
          id: row.id,
        })
        .then((res) => {
          if (res.code !== 0) this.$message.error(res.message);
          if (res.data) {
            this.deviceDialogData = res.data.map((item) => item.device_id)
          } else {
            this.deviceDialogData = []
          }
          this.currentId = row.id
          this.deviceVisible = true
        })
    },
    handleDeviceClose(data) {
      this.deviceVisible = false

      if (data) {
        const list = data.map((item) => item.id)
        api.pass
          .updateRuleDevice({ id: this.currentId, device_id: list })
          .then((res) => {
            if (res.code !== 0) this.$message.error(res.message);
            this.getData()
          })
      }
    },
    handlePassUser(row) {
      api.pass
        .queryRuleUser({
          id: row.id,
        })
        .then((res) => {
          if (res.code !== 0) this.$message.error(res.message);
          if (res.data) {
            this.userDialogData = res.data.map((item) => {
              return { id: item.user_id, name: item.user_name }
            })
          } else {
            this.userDialogData = []
          }
          this.currentId = row.id
          this.userVisible = true
        })
    },
    handleUserClose(data) {
      this.userVisible = false
      if (data) {
        api.pass
          .updateRuleUser({
            id: this.currentId,
            user_id: data.map((item) => item.id),
          })
          .then((res) => {
            if (res.code !== 0) this.$message.error(res.message);
            this.getData()
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
