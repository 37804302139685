<template>
<div class="main">
    <el-tabs v-model="activeName">
        <el-tab-pane label="通行记录" name="1">
            <PassLogs v-if="activeName ==='1'" />
        </el-tab-pane>
        <el-tab-pane label="陌生人记录" name="2">
            <StrangerLogs v-if="activeName ==='2'" />
        </el-tab-pane>
    </el-tabs>
</div>
</template>

<script>
import PassLogs from './PassLogs.vue'
import StrangerLogs from './StrangerLogs.vue'

export default {
    components: { PassLogs, StrangerLogs },
    data() {
        return {
            activeName: "1",
        }
    },
}
</script>

<style lang="scss" scoped>
</style>
