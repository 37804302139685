<template>
<el-dialog width="1000px" :visible.sync="visible" top="2vh" :before-close="()=>handleClose(false)" :close-on-click-modal="false" @open="dialogOpen">
    <div slot="title" class="dialog-title">{{dialogTitle}}</div>

    <transition name="bounce">
        <div class="statisticsBox" v-show="show">
            <el-row type="flex" justify="center" align="middle" class="float-left leftBox">
                <el-col :span="6" class="left-content">
                    <div class="num">{{statisticsData.totalCount}}</div>
                    <div class="name">应到人数</div>
                </el-col>
                <el-col :span="6" class="left-content">
                    <div class="num">{{statisticsData.zhunshi}}</div>
                    <div class="name">准时</div>
                </el-col>
                <el-col :span="6" class="left-content">
                    <div class="num num2">{{statisticsData.chidao}}</div>
                    <div class="name">迟到</div>
                </el-col>
                <el-col :span="6" class="left-content">
                    <div class="num num2">{{statisticsData.weichuxi}}</div>
                    <div class="name">未签到</div>
                </el-col>
            </el-row>
            <el-row class="float-left rightBox">
                <el-col :span="12" class="right-content">出勤率</el-col>
                <el-col :span="12" class="right-content" style="padding:20px 0 0 0">
                    <el-progress type="circle" :percentage="statisticsData.chuqinlv" :width="110" :stroke-width="8" />
                </el-col>
            </el-row>
        </div>
    </transition>

    <div>
        <el-form :model="searchForm" inline label-position="left" size="small">
            <el-form-item label="签到状况">
                <el-select v-model="searchForm.status" size="small">
                    <el-option label="全部" :value="-1" />
                    <el-option label="未签到" :value="0" />
                    <el-option label="准时" :value="1" />
                    <el-option label="迟到" :value="2" />
                </el-select>
            </el-form-item>

            <el-form-item class="float-right">
                <el-button type="primary" v-waves :loading="downLoadExcelLoading" :disabled="tableData.length==0" @click="handleExport" style="width:85px">导出</el-button>
            </el-form-item>
        </el-form>

        <el-table :header-cell-style="{background:'#FAFAFA'}" :data="getTableData()" border stripe height="500">
            <el-table-column prop="user_name" label="参会人员" align="center" />
            <el-table-column prop="dept_name" label="部门" align="center" />
            <el-table-column label="签到状况" align="center">
                <template slot-scope="{row}">
                    <span :style="{ color: row.status === 0 ? 'red' : row.status === 1 ? 'green' : 'orange' }">{{ row.state }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="onTime" label="签到时间" align="center" />
        </el-table>
    </div>
</el-dialog>
</template>

<script>
import api from '@/api'
import { timestampToDateStr } from '@/utils'

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        row: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            dialogTitle: "统计详情",
            show: false,
            submitLoading: false,
            downLoadExcelLoading: false,

            searchForm: {
                status: -1,
            },
            tableData: [],

            statisticsData: {
                totalCount: 0,
                zhunshi: 0,
                chidao: 0,
                weichuxi: 0,
                chuqinlv: 0
            }
        };
    },

    methods: {
        // 导出数据
        handleExport() {
            this.downLoadExcelLoading = true;
            require.ensure([], () => {
                // eslint-disable-next-line camelcase
                const { export_json_to_excel } = require("@/assets/excel/Export2ExcelColor");
                const title = [`${this.row.title} ${this.row.meetDate} 统计详情`, '', '', '']
                const tHeader = [
                    ['参会人员', '部门', '签到状况', '签到时间']
                ]
                const filterVal = ["user_name", "dept_name", "state", "onTime"];

                // console.log(tHeader[0].length, tHeader[1].length, title.length, filterVal.length);
                const data = this.formatJson(filterVal, this.tableData);
                data.map(item => {
                    item.map((i, index) => { if (!i) item[index] = '' })
                })
                const merges = [
                    ["A1:D1"],
                ]
                export_json_to_excel({ title, header: tHeader, data, merges, filename: `${this.row.title} ${this.row.meetDate} 统计详情`, autoWidth: 40 });
            });

            setTimeout(() => {
                this.downLoadExcelLoading = false;
            }, 2200);
        },
        formatJson(filterVal, jsonData) {
            return jsonData.map((v) => filterVal.map((j) => v[j]));
        },

        // 关闭弹出层
        handleClose(bool) {
            this.show = false
            this.$emit("handleClose", bool);
        },

        // 获取表格数据（名称查找功能和分页）
        getTableData() {
            if (this.searchForm.status === -1) {
                return this.tableData
            } else {
                return this.tableData.filter(item => item.status === this.searchForm.status);
            }
        },

        // 会议签到状态转换
        changStatus(n) {
            const arr = ['未签到', '准时', '迟到']
            return arr[n]
        },

        // 获取统计详情
        async getDetail() {
            const res = await api.pass.meetStatisticsQuery({ meet_id: this.row.id });
            if (res.code !== 0) this.$message.error(res.message);
            const data = res.data
            const totalCount = data.meet_user.length
            const zhunshi = data.on_time_count
            const chidao = data.user_late_count
            const weichuxi = totalCount - zhunshi - chidao
            const chuqinlv = Math.ceil((zhunshi + chidao) / totalCount * 100) || 0

            this.statisticsData.totalCount = totalCount
            this.statisticsData.zhunshi = zhunshi
            this.statisticsData.chidao = chidao
            this.statisticsData.weichuxi = weichuxi
            this.statisticsData.chuqinlv = chuqinlv

            const list = data.meet_user || []
            this.tableData = list.map(item => {
                item.state = this.changStatus(item.status)
                item.onTime = timestampToDateStr(item.identify_time, 'YYYY-MM-DD HH:mm:ss')
                return item
            })

            console.log(this.tableData)
        },

        // 打开弹框
        async dialogOpen() {
            console.log(this.row)
            this.dialogTitle = `${this.row.title} ${this.row.meetDate} 统计详情`
            await this.getDetail()
            setTimeout(() => {
                this.show = true
            }, 100)
        },
    },
};
</script>

<style lang="scss" scoped>
.bounce-enter-active {
    animation: bounce-in 0.5s;
}

.bounce-leave-active {
    animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1.25);
    }

    100% {
        transform: scale(1);
    }
}

.statisticsBox {
    width: 100%;
    height: 150px;
    padding: 25px 0;
}

.leftBox {
    width: 480px;
    height: 100%;
    padding: 0 20px;

    .left-content {
        height: 100px;
        text-align: center;
        padding-top: 20px;

        .num {
            font-size: 30px;
            font-weight: bolder;
            color: rgb(50, 185, 240);
            margin-bottom: 15px;
        }

        .num2 {
            color: orange
        }

        .name {
            font-size: 16px;
            color: black;
        }
    }
}

.rightBox {
    width: 260px;
    height: 100%;
    margin-left: 80px;

    .right-content {
        height: 150px;
        line-height: 150px;
        text-align: center;
        font-size: 16px;
        color: black;

    }
}

::v-deep .el-progress__text {
    font-size: 26px !important;
}
</style>
