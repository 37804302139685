<template>
<el-dialog width="888px" :visible.sync="visible" top="2vh" :before-close="() => handleClose(false)" :close-on-click-modal="false" @open="dialogOpen">
    <div slot="title" class="dialog-title">{{dialogTitle}}</div>
    <el-form :model="formData" size="small" ref="ruleForm" :rules="rules" label-width="100px" style="padding:0 20px">
        <el-form-item label="设备名称" prop="name">
            <el-input v-model="formData.name" placeholder="请输入区域名称（15字内）" autocomplete="off" />
        </el-form-item>
        <!-- <el-form-item label="旧密码">
            <el-input v-model="device_config.old_pass_word" placeholder="请输入旧密码" autocomplete="off" show-password />
        </el-form-item>
        <el-form-item label="新密码">
            <el-input v-model="device_config.new_pass_word" placeholder="请输入新密码" autocomplete="off" show-password />
        </el-form-item> -->
        <el-form-item label="设备类型" prop="type">
            <el-radio-group v-model="formData.type">
                <el-radio :label="255">访客</el-radio>
                <el-radio :label="1">考勤</el-radio>
                <el-radio :label="2">门禁</el-radio>
                <el-radio :label="4">会议</el-radio>
                <el-radio :label="8">测温考勤</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="设备方向" v-if="formData.type === 1 || formData.type === 8">
            <el-radio-group v-model="device_direction">
                <el-radio :label="0">未知</el-radio>
                <el-radio :label="1">进校</el-radio>
                <el-radio :label="2">离校</el-radio>
                <el-radio :label="6">进入宿舍</el-radio>
                <el-radio :label="7">离开宿舍</el-radio>
                <el-radio :label="8">上车(校车)</el-radio>
                <el-radio :label="9">下车(校车)</el-radio>
            </el-radio-group>
        </el-form-item>

        <el-form-item label="语音播报">
            <el-radio-group v-model="device_config.tts_mod_type">
                <el-radio :label="1">不播报</el-radio>
                <el-radio :label="2">播报名字</el-radio>
                <el-radio :label="100">自定义</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="语音内容" v-if="device_config.tts_mod_type===100">
            <el-input v-model="device_config.tts_mod_content" placeholder="请输入内容" autocomplete="off" />
        </el-form-item>

        <el-form-item label="屏幕文字">
            <el-radio-group v-model="device_config.display_mod_type">
                <el-radio :label="2">不显示</el-radio>
                <el-radio :label="1">显示名字</el-radio>
                <el-radio :label="100">自定义</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="文字内容" v-if="device_config.display_mod_type===100">
            <el-input v-model="device_config.display_mod_content" placeholder="请输入内容" autocomplete="off" />
        </el-form-item>

        <el-form-item label="识别阈值">
            <el-input-number v-model="device_config.identify_scores" :min="10" :max="100" step-strictly style="width:180px" />
        </el-form-item>
        <!-- <el-form-item label="继电器输出">
            <el-radio-group v-model="device_config.is_open_relay">
                <el-radio :label="1">输出</el-radio>
            </el-radio-group>
        </el-form-item> -->
        <el-form-item label="刷卡识别">
            <el-radio-group v-model="device_config.rec_mode_card_enable">
                <el-radio :label="1">关</el-radio>
                <el-radio :label="2">开</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="刷脸识别">
            <el-radio-group v-model="device_config.rec_mode_face_enable">
                <el-radio :label="1">关</el-radio>
                <el-radio :label="2">开</el-radio>
            </el-radio-group>
        </el-form-item>
    </el-form>
    <div style="text-align:center;">
        <el-button class="footer-btn" size="small" @click="handleClose(false)">取 消</el-button>
        <el-button class="footer-btn" :loading="submitLoading" type="primary" size="small" :disabled="row.device_state !== 1" @click="submitForm('ruleForm')">保 存</el-button>
    </div>
    <el-collapse v-model="activeNames" style="margin-top:10px">
        <el-collapse-item name="1">
            <el-divider slot="title">
                <span>高级设置</span>
                <i class="el-collapse-item__arrow el-icon-arrow-down" :class="activeNames.length > 0 ? 'is-active' : ''" style="display:inline-block" />
            </el-divider>
            <el-row style="margin-bottom:10px;">
                <el-button class="setting-btn" size="small" @click="onProcess('/v100/school/device/init', '设备初始化')">设备初始化</el-button>
            </el-row>
            <!-- <template v-if="row.device_state === 1"> -->
            <el-row style="margin-bottom:10px;">
                <!-- <span class="label">口罩检测模式：</span> -->
                <el-button class="setting-btn" size="small" @click="handleMaskCheck(1)">开启口罩监测</el-button>
                <el-button class="setting-btn" size="small" @click="handleMaskCheck(2)">关闭口罩监测</el-button>
            </el-row>
            <el-row style="margin-bottom:10px;">
                <el-button class="setting-btn" size="small" @click="onProcess('/v100/school/device/delete', '设备删除')">设备删除</el-button>
                <el-button class="setting-btn" size="small" @click="onProcess('/v100/school/device/reset', '设备重置')">设备重置</el-button>
                <el-button class="setting-btn" size="small" @click="onProcess('/v100/school/device/restart', '设备重启')">设备重启</el-button>
                <el-button class="setting-btn" size="small" @click="onProcess('/v100/school/device/clear/user','清空人员数据')">清空人员数据</el-button>
            </el-row>
            <!-- <el-row>
                    <el-button class="setting-btn" size="small" @click="onProcess('/v100/school/device/clear/user','清空人员数据')">清空人员</el-button>
                    <el-button class="setting-btn" size="small" @click="onProcess('/v100/school/device/clear/card','清空卡号数据')">清空卡号数据</el-button>
                    <el-button class="setting-btn" size="small" @click="onProcess('/v100/school/device/clear/user','清空人员数据')">清空人员数据</el-button>
                    <el-button class="setting-btn" size="small" @click="onProcess('/v100/school/device/sync/card','同步卡号数据')">同步卡号数据</el-button>
                    <el-button class="setting-btn" size="small" @click="onProcess('/v100/school/device/sync/face','同步人脸数据')">同步人脸数据</el-button>
                    <el-button class="setting-btn" size="small" @click="onProcess('/v100/school/device/sync/user','同步人员数据')">同步人员数据</el-button>
                    </el-row>-->

            <el-row>
                <el-form size="small" label-width="90px">
                    <!-- <el-form-item label="修改密码：">
                            <el-row type="flex">
                                <el-row type="flex">
                                    <span>原密码：</span>
                                    <el-input v-model="device_config.old_pass_word" placeholder="请输入旧密码" autocomplete="off" show-password />
                                </el-row>
                                <el-row type="flex">
                                    <span> 新密码：</span>
                                    <el-input v-model="device_config.new_pass_word" placeholder="请输入新密码" autocomplete="off" show-password />
                                </el-row>
                            </el-row>
                        </el-form-item> -->

                    <el-form-item label="修改密码" label-position="left">
                        <el-row type="flex">
                            <el-form-item label="旧密码：" style="margin-right:55px">
                                <el-input v-model="device_config.old_pass_word" placeholder="请输入旧密码" autocomplete="off" show-password />
                            </el-form-item>
                            <el-form-item label="新密码：">
                                <el-input v-model="device_config.new_pass_word" placeholder="请输入新密码" autocomplete="off" show-password />
                            </el-form-item>
                        </el-row>
                    </el-form-item>
                </el-form>
            </el-row>
            <!-- </template> -->
        </el-collapse-item>
    </el-collapse>
</el-dialog>
</template>

<script>
import api from '../../api'
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        row: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            dialogTitle: '设备设置',
            submitLoading: false,

            activeNames: [],

            formData: {
                name: '',
                type: '',
            },
            device_direction: "",
            device_config: {
                display_mod_content: "",
                identify_scores: 70,
                is_open_relay: 1,
                rec_mode_card_enable: 2,
                rec_mode_face_enable: 2,
                display_mod_type: 1,
                tts_mod_type: 1,
                tts_mod_content: "",
                old_pass_word: "",
                new_pass_word: "",
            },

            device_id: '',

            maskCheck: false,

            rules: {
                name: [
                    { required: true, message: '会议室名称不能为空' },
                    { min: 2, max: 16, message: '长度在2到15个字符之间' },
                ],
            },
        }
    },

    methods: {
        dialogOpen() {
            console.log(this.row)
            const row = this.row
            this.device_id = row.id
            this.formData.name = row.device_name
            this.formData.type = row.device_type
            this.device_config.display_mod_content = row.device_config.display_mod_content
            this.device_config.identify_scores = row.device_config.identify_scores
            this.device_config.is_open_relay = row.device_config.is_open_relay
            this.device_config.rec_mode_card_enable = row.device_config.rec_mode_card_enable
            this.device_config.rec_mode_face_enable = row.device_config.rec_mode_face_enable
            this.device_config.display_mod_type = row.device_config.display_mod_type
            this.device_config.tts_mod_type = row.device_config.tts_mod_type
            this.device_config.tts_mod_content = row.device_config.tts_mod_content
        },
        handleClose(bool) {
            this.$emit('handleClose', bool)
        },
        handleMaskCheck(v) {
            this.$confirm(`是否${v === 1 ? '开启' : '关闭'}口罩检测功能`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                .then(async () => {
                    const loading = this.$loading({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });

                    api.pass.maskDetection({
                        device_id: this.device_id,
                        mask_detection: v
                    }).then(() => {
                        loading.close()
                        this.$message.success(`${v === 1 ? '开启' : '关闭'}成功`);
                        this.handleClose(false)
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消',
                    })
                })

        },
        // 提交新增
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.submitLoading = true
                    const data = await api.pass.updateDevice({
                        device_id: this.device_id,
                        device_name: this.formData.name,
                        device_type: this.formData.type,
                        device_direction: this.device_direction,
                        device_config: this.device_config
                    })
                    this.submitLoading = false
                    if (data.code !== 0) {
                        this.$message.error(data.message)
                    } else {
                        this.$message.success('修改成功~')
                        // this.$refs[formName].resetFields()
                    }
                    this.handleClose(true)
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        onProcess(url, msg) {
            this.$confirm(`是否要进行 ${msg} 操作？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                .then(async () => {
                    const res = await api.pass.processDevice(url, { device_id: this.device_id })
                    if (res.code === 0) {
                        this.$message.success('操作成功')
                    } else {
                        this.$message.error('操作失败')
                    }
                    this.handleClose(false)
                })
                .catch(() => {
                    this.$message.info('取消')
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.dialog-title {
    font-size: 15px;
    font-weight: 600;
    text-align: center;
}

.footer-btn {
    width: 100px;
}

.footer-btn-cancel {
    margin-right: 10px;
}

.label {
    width: 110px;
    display: inline-block;
    height: 20px;
    font: 14px/20px "Microsoft YaHei";
    vertical-align: middle;
    margin-right: 10px;
}

.setting-btn {
    width: 110px;
}

::v-deep .el-collapse-item__wrap {
    border: none;
}

::v-deep .el-collapse {
    border: none;
}

::v-deep .el-collapse .el-collapse-item__header {
    border: none !important;
}

::v-deep .el-collapse .el-collapse-item__arrow {
    margin: 0 0 0 8px;
    font-weight: 600;
    display: none;
}

::v-deep .el-collapse-item__arrow.is-active {
    transform: rotate(180deg);
}
</style>
